export default {
    menu:{
        about:"关于我们",
        customize:"定制开发",
        website:"网站开发",
        app:"APP开发",
        wechat:"微信开发",
        store:"网上商城",
        it:"IT支援服务",
        contact:"联系我们",
        tvp:"申请科技券服务",
        funding:"申请资助",
        DTSPP:"数码转型支援先导计划",
        quotation:"获取报价",
        consultation:"立即咨询",
        cloud:"云备份",
    },
    index:{
        welcome:"欢迎",
        come:"来到",
        name:"中天科技系统有限公司",
        more:"深入了解",
        customize0:"订制开发您独一无二的系统",
        customize1:"固定资产系统",
        customize2:"贷款系统",
        customize3:"QC验货系统",
        customize4:"补习社系统",
        customize5:"批发系统",
        customize6:"CRM系统",
        customize7:"迷你仓系统",
        customize8:"网上商城",
        customize9:"ERP系统",
        customize10:"预约系统",
        customize11:"排队系统",
        customize12:"MRP系统",
        customize13:"贸易系统",
        customize14:"仓存系统",
        customize15:"OA系统",
        customize16:"装修平台",
        customize17:"其它",
        website1:"网站定制开发",
        website2:"网站定制开发是根据客户需求打造独一无二的网站解决方案，提供个性化、灵活性和可扩展性",
        app0:"根据客户需求定制开发独特的APP",
        app1:"预约系统APP",
        app2:"仓存APP",
        app3:"补习社APP",
        app4:"MRP APP",
        app5:"迷你仓APP",
        app6:"CRM APP",
        app7:"会员APP",
        app8:"OA APP",
        app9:"商城APP ",
        wechat1:"微信定制开发",
        wechat2:"微信开发，创造无限可能！公众号、小程序、企业微信，提供个性化互动和业务扩展",
        mall1:"多国货币",
        mall2:"多种语言",
        mall3:"赠劵折扣功能",
        mall4:"购物车",
        mall5:"商品搜寻",
        mall6:"线上付款功能",
        mall7:"会员系统",
        mall8:"翻查购买纪录",
        it1:"我们是领先业界的IT支援服务提供商，为您提供全方位的技术支持和解决方案。",
        it2:"我们的专业团队会根据您的需求，提供个人化的IT支援服务，确保您的系统和设备的顺利运作。",
        it3:"无论是即时支援还是定期维护，我们都致力于为您提供无忧的IT体验，让您专注于业务发展。",
        thank:"谢谢您的查询，我们会尽快与你联络",
    },
    footer:{
        company1:"中天科技系统有限公司",
        company2:"中天动力系统(深圳)有限公司",
        address:"地址",
        address1:"沙田火炭㘭背湾街53-55号美高工业大厦3/F D38室",
        address2:"深圳市罗湖区江背路8号庐山大厦C座17A11室",
        mail:"电邮",
        tel1:"(852) 24902300",
        tel2:"(0755) 25100512",
        map:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1844.402473348265!2d114.1933091!3d22.3987095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34040634eb551603%3A0x6f45e5cf90879d4!2z54Gr54Kt5Z2z6IOM54Gj6KGXNTPomZ_nvo7pq5jlt6Xmpa3lpKflu4g!5e0!3m2!1szh-TW!2shk!4v1743059071030!5m2!1szh-TW!2shk"
    },
    form:{
        placeholderName: '请输入姓名',
        placeholderTel: '请输入联络电话',
        placeholderEmail: '请输入电邮地址',
        placeholderText: '请输入留言',
        placeholderEmail2: '请输入正确电邮地址',
        name: "姓名：",
        mail: "电邮：",
        tel: "电话：",
        message: "留言：",
        submit: "提交",
        successText: '验证通过！ ',
        failText: '验证失败，请重试！ ',
        sliderText: "向右滑动",
        titles: "留言",
        titleText:"如果您有任何疑问，请联络我们，我们很高兴能为您解答疑问",
        tips:"星号为必填项",
        online:"租用网上商城"
    },
    project: {
        caseTit: '我们的案例',
        JiaZaiGD: '查看更多',
        ChaKan: '查看'
    },
    page:{
        quotation: "免费即时报价",
        getQuotation:"更多系统定制开发，请与我们联系",
        customizeName0: "系统开发",
        customizeName1: "产品开发",
        customizeIntro1: "中天科技是一站式的电脑程式设计外包服务商:需求分析、定制解决方案、体系结构、UI/UX设计、开发、测试、部署、维护、系统管理和支持",
        customizeName2: "专业团队",
        customizeIntro2: "我们会反复检查您的目标及需求，并为您提供最适合实施方案",
        customizeName3: "现有系统支持及升级",
        customizeIntro3: "我们提供将现有系统支持及升级到新兴技术，同时亦保留原有的数据",
        customizeName4: "除错服务",
        customizeIntro4: "除我们提供远端除错服务，不管相隔多远，我们的团队都会像在你身旁与你沟通",
        customizeName5: "支持与维护",
        customizeIntro5: "客户可以通过电话或电子邮件跟我们的软件工程师沟通，我们的软件工程师会作出最快的响应，并修复代码中的错误",
        customizeName6: "代码审查服务",
        customizeIntro6: "将彻底检查现有产品的代码，以确保其可靠性、可维护性、效率和安全性方面符合最佳状况无论您的企业规模如何，我们都能够提供高效的IT支持和解决方案，针对您的具体需求定制服务。立即联系我们，获取更多信息，并获得有关我们的Help Desk和其他IT服务的免费报价。让我们帮助您摆脱IT困扰，专注于核心业务的发展",
        customizeTitle: "度身订制专属于您的系统",
        customizeName7: "贷款系统",
        customizeName71: "客户资料模组",
        customizeName72: "贷款管理模组",
        customizeName73: "还款管理模组",
        customizeName74: "客户资料模组",
        customizeName8: "贸易系统",
        customizeName81: "业务模组",
        customizeName82: "采购模组",
        customizeName83: "库存模组",
        customizeName84: "财务模组",
        customizeName9: "MRP生产管理系统",
        customizeName91: "销售模组",
        customizeName92: "生产模组",
        customizeName93: "采购模组",
        customizeName94: "质检模组",
        customizeName95: "仓存模组",
        customizeName10: "批发系统",
        customizeName101: "设置零售商",
        customizeName102: "设置各种水果名称及价钱",
        customizeName103: "生成订单",
        customizeName104: "整合总订单数量",
        customizeName105: "生成出货单及帐单",
        customizeName11: "装修平台",
        customizeName111: "用户注册及登入",
        customizeName112: "设置可提供之服务",
        customizeName113: "师傅注册及登入",
        customizeName114: "管理员审批师傅",
        customizeName115: "用户下单",
        customizeName116: "师傅接单",
        customizeName117: "完工后付款及用户对师傅评价",
        customizeName12: "固定资产系统",
        customizeName121: "用户注册及登入",
        customizeName122: "设置可提供之服务",
        customizeName123: "师傅注册及登入",
        customizeName124: "管理员审批师傅",
        customizeName125: "用户下单",
        customizeName126: "师傅接单",
        customizeName127: "完工后付款及用户对师傅评价",
        customizeName13: "补习社系统",
        customizeName131: "用户注册及登入",
        customizeName132: "设置免费试用期",
        customizeName133: "分中、小学生制",
        customizeName134: "小学生设有每日上传家课册功能",
        customizeName135: "即问即答功能–线上问功课",
        customizeName136: "不同科目之补充练习发放",
        customizeName137: "购买会员及续费服务",
        customizeName14: "迷你仓系统",
        customizeName141: "线上付款",
        customizeName142: "用户下单及预约送箱",
        customizeName143: "用户拍照物件",
        customizeName144: "设置每种不同收费",
        customizeName145: "线管理员清楚看到每张订单的详细资料上付款",
        customizeName146: "送箱人员收到送箱订单及联络人资料",
        customizeName147: "系统每日提示到期客户订单",
        customizeName148: "用户注册及登入",
        customizeName15: "迷你仓系统",
        customizeName151: "开报价单、保留不同版本报价单",
        customizeName152: "开Invoice及保存客户确认之报价单",
        customizeName153: "生成应收应付帐",
        customizeName154: "员工上班打卡，每月计算工资",
        customizeName155: "请假申请，看到自己的剩余年假及审批状态",
        customizeName156: "报销申请，看到审批状态",
        customizeName16: "QC验货系统",
        customizeName161: "从SAP系统中抽取所需要的验货单资料导入到APP中",
        customizeName162: "管理员在APP把验货单分配到相关的验货员帐号",
        customizeName163: "验货员登入可看到各自需负责的验货单资料、验货日期及工厂地址等资料",
        customizeName164: "验货员验货拍照及填写验货资料，验货后工厂负责人可在APP中签名，系统生成验货报告",
        customizeName165: "用户可将验货报告发送email",
        quotationNeed: "获取报价",
        bottom:"迅速报价，优质方案",
        website1:"响应式网站和传统网站对比",
        website2:"响应式网站和传统网站是两种不同的网站设计方法，它们在设计和使用上有一些重要的区别。响应式网站则采用了弹性布局，能够根据访问设备的屏幕尺寸和分辨率自动调整网站的外观和布局。",
        websiteName3:"响应式网站",
        websiteTxt311:"设计方式：",
        websiteTxt312:"响应式网站则采用了弹性布局，能够根据访问设备的屏幕尺寸和解析度自动调整网站的外观和布局。",
        websiteTxt321:"使用体验：",
        websiteTxt322:"响应式网站能够提供更好的使用体验，因为它能够适应各种设备，包括桌面电脑、平板电脑和手机等。不论用户使用何种设备访问网站，都能获得良好的浏览体验。",
        websiteTxt331:"开发成本：",
        websiteTxt332:"响应式网站通常需要额外的开发工作，因为它需要根据不同的屏幕尺寸和解析度调整布局和设计。这意味着开发响应式网站可能需要更多的时间和资源。",
        websiteTxt341:"SEO 优化：",
        websiteTxt342:"由于响应式网站提供了一个统一的 URL 和 HTML 代码，对于搜索引擎优化（SEO）来说更为有利。",
        websiteName4:"传统网站",
        websiteTxt41:"传统网站通常是针对桌面电脑的设计，使用固定尺寸和布局。",
        websiteTxt42:"传统网站在较小的屏幕上可能需要水平滚动或缩放以便查看内容，使用体验不如响应式网站便捷。",
        websiteTxt43:"传统网站只需要设计一个固定的版本，相对来说较为简单。",
        websiteTxt44:"传统网站如果有多个版本（例如桌面版和移动版），可能需要额外的 SEO 努力来确保每个版本都能获得良好的排名。",
        websiteName5:"多终端显示的优势",
        websiteTxt5:"提供良好的跨设备使用体验。提高网站的可访问性。增加用户参与度和互动性。有利于搜索引擎优化。",
        appName1:"原生APP",
        appTxt1:"原生APP是专为特定平台（例如iOS、Android）使用原生程式语言（例如Objective-C/Swift、Java/Kotlin）开发的应用程式。它们能够完全利用平台的功能和性能，提供最佳的使用者体验。原生APP通常需要单独开发和维护，并且需要分别针对不同平台进行开发。总的来说，原生APP提供最佳的使用者体验，但需要单独针对不同平台进行开发。",
        appName2:"混合APP",
        appTxt2:"混合APP是使用Web技术（HTML、CSS、JavaScript）开发的应用程式，然后使用框架（如React Native、Ionic）将其封装为原生应用程式。混合APP可以跨平台运行，并且可以重用部分程式码，从而节省开发成本和时间。然而，它们可能无法完全拥有原生APP的性能和功能。总的来说，混合APP具有跨平台的优势，但可能欠缺原生APP的性能。",
        appName3:"Web APP",
        appTxt3:"Web APP是使用Web技术（HTML、CSS、JavaScript）开发的应用程式，可以在网页浏览器中运行。Web APP不需要进行安装，用户可以通过浏览器直接访问。它们具有跨平台性和即时更新的优势，但可能受到网路连接和浏览器功能的限制。总的来说，Web APP具有跨平台和即时更新的优势，但在功能和性能方面可能受到限制。",
        wechatName1:"微信小程序",
        wechatTxt1:"微信小程序是在微信平台上运行的轻量级应用程式，用户可以在微信中直接使用，不需要额外的安装。它们提供了各种功能，包括线上购物、社交媒体、生活服务等。微信小程序开发使用的是小程序专用框架（如微信原生框架、Taro、uni-app），可以实现跨平台运行，并且具有较好的用户体验和性能。总的来说，微信小程序是在微信平台上运行的轻量级应用程式。",
        wechatName2:"微信公众号",
        wechatTxt2:"微信公众号是一种基于微信平台的官方帐号，用户可以订阅并接收公众号发布的讯息、文章、图片、视频等。它们通常由企业、组织、名人等拥有和运营，用于品牌推广、信息传递、客户互动等目的。微信公众号可以分为订阅号和服务号两种类型，功能和权限有所区别。总的来说，微信公众号是官方帐号用于信息传递和品牌推广。",
        wechatName3:"企业微信",
        wechatTxt3:"企业微信是针对企业内部使用的企业通讯和协作平台，类似于企业版的微信。它提供了企业内部通讯、组织架构、工作流程、应用程式集成等功能，用于加强企业内部沟通和协作。企业微信还支持企业应用程式的开发和定制，使企业能够根据自身需求扩展功能。总的来说，企业微信则是企业内部通讯和协作平台。",
        mall1:"探索无界购物体验：网上商城带您进入全新电子商务世界",
        mall111:"商品管理",
        mall2:"购物车功能",
        mall3:"收藏",
        mall4:"营销活动",
        mall5:"订单管理",
        mall6:"登录/注册",
        mall7:"会员中心",
        mall8:"售后退款",
        mall9:"租用限时优惠",
        mall10:"网站+APP商城（iOS+Android）",
        mall11:"年",
        mall12:"购买",
        mall13:"购买包含",
        mall14:"3种语言版本（繁、简、英）",
        mall15:"含一年云端空间10G空间及每月50G流量",
        mall16:"优惠价-第二年开始收取HKD 2,900.00元（云端空间及维护费用-含同样的服务即10G空间及每月50G流量，系统除错服务及app版本更新)",
        mall17:"第三年开始取正价HKD 5,800.00元（云端空间及维护费用-含同样的服务即10G空间及每月50G流量，系统除错服务及app版本更新)",
        mall19:"只接入最多4种支付方式，多于4种会额外收取费用",
        it0:require('@/assets/img/IT_pic01-cn.png'),
        it1:"服务范围覆盖中港两地",
        itName1:"资料备份和保护",
        itTxt1:"资料备份和保护是将数据复制到另一个位置或储存媒体，以防止数据丢失、损坏或未授权访问。它包括定期备份数据、制定备份策略、加密数据、监控和测试备份系统的有效性。这些措施确保数据的可靠性和安全性，并提供应对数据灾害的准备。",
        itName2:"桌面维护",
        itTxt2:"桌面维护是管理和支持计算机桌面环境的活动，包括系统监控、软件安装、病毒防护、数据备份和用户支持。它旨在提供稳定、安全和高效的桌面工作环境，确保用户顺利运作和数据安全。",
        itName3:"电脑技术支援",
        itTxt3:"电脑技术支援是提供解决电脑问题和技术支持的服务，包括软件安装、故障排除、病毒防护、网络连接、硬件维修和用户支持等。",
        itTxt31:"通常，电脑技术支援可以通过多种方式提供，包括远程支援、电话支援、在场支援和线上资源。",
        itTxt32:"简而言之，电脑技术支援是提供解决电脑和技术问题的服务，帮助用户顺利使用并维护他们的系统。它是确保顺利运行并充分利用电脑技术的关键一环。",
        itName4:"IT维护",
        itTxt4:"IT维护是指对信息技术（IT）基础设施和系统进行管理、保养和支持的一系列活动。它旨在确保IT资源的稳定运行，提供高效、安全和可靠的IT环境。",
        itTxt41:"目标是确保IT系统和资源的正常运行，最大程度地减少系统故障和数据损失的风险，提高组织的生产力和效率。它是企业和组织中IT部门的重要职能之一，有助于确保IT资源的可用性、安全性和可靠性。",
        itName5:"网路管理",
        itTxt5:"网路管理是指对计算机网络基础设施进行有效管理和监控的活动。它涵盖了网络设备、连接、资源和安全性等方面的管理。",
        itTxt51:"主要目标是确保网络的可用性、性能和安全性，以满足组织的需求并支持业务运作。",
        itTxt52:"确保网络运作顺利、安全可靠的关键一环。它有助于提高网络的效能、降低故障风险，并提供良好的用户体验。组织和企业可以借助网路管理来管理和维护他们的网络基础设施，以支持业务的顺利运作。",
        itName6:"伺服器管理",
        itTxt6:"伺服器管理是指对伺服器硬体和软体进行有效管理和监控的一系列活动。它包括伺服器的配置、安装、维护和监测，以确保伺服器的正常运行和高效性能。",
        itTxt61:"主要目标是确保伺服器的可用性、安全性和效能，以满足组织的需求并支持业务运作。",
        itTxt62:"确保伺服器运行顺利、安全可靠的重要一环。它有助于提高伺服器的效能、减少故障风险，并确保资料的完整性和可用性。组织和企业可以通过有效的伺服器管理，确保其业务系统的稳定运行，并满足不断变化的需求。",
        itReason:"选择CHINA SKY的理由",
        itReason1:"专业技术：拥有丰富的IT技术知识和经验。",
        itReason2:"个人化服务：提供量身定制的解决方案，关注客户的独特需求。",
        itReason3:"及时响应：快速响应并提供解决方案，减少停工时间。",
        itReason4:"综合解决方案：提供全方位的IT支援服务，节省时间和精力。",
        itReason5:"信任和信誉：拥有良好的信誉和口碑，与众多客户建立长期合作关系。",
        rentBtn: "租用",
        //
        look: "查看案例",
        addStore01:"中天科技系统周年庆",
        addStore02:"网上商城租赁",
        addStore03:"惊爆价！！",
        addStore04:"HKD",
        addStore05:"年",
        addStore06:"包含网站+APP商城（iOS+Android)",
        addStore07:"原价：HKD 22,800.00/年",
        addStore08:"立即租用",
        addStore09:"网上商城租赁服务",
        addStore10:"我们的网上商城租赁服务，为您免去昂贵的自主开发费用，节约您的开发时间，让您的业务尽快开展，服务包含网站与App，各类商品类型均可通用，一站式打造您的网上商城。",
        addStore11:"会员系统",
        addStore12:"商品系统",
        addStore13:"购物车",
        addStore14:"订单系统",
        addStore15:"在线支付",
        addStore16:"报表导出功能",
        addStore17:"折扣优惠功能",
        addStore18:"积分抵扣现金功能",
        addStore19:"支持繁、简、英三种语言",
        addStore20:"一年云端空间10GB,每月50G流量",
        addStore21:require('@/assets/img/ad-cn.png'),
    },
    video:{
        url:require('@/assets/img/video-cn.mp4'),
        subtitles1:"现今网络世界的进化速度惊人,为了让客户感到更亲切和方便，企业必须拥有完善的IT系统和网络平台。",
        subtitles2:"中天科技系统有限公司，于2004年在香港成立，同年于深圳开设分公司。",
        subtitles3:"我们一直为香港和内地的企业提供IT外包服务：包括固定检查、网路管理、网路安全、VPN解决方案等。",
        subtitles4:"由于我们于中港均有专业的技术人员，所以不论客户于香港或内地遇上IT问题都可以提供最即时和合适的支援。",
        subtitles5:"近年网络行销为企业提供了全新的商业机会，社交平台的相关程式和服务成为了各企业强大的工具。",
        subtitles6:"中天科技对每个项目由设计，开发及线上推广，都掌握了全面相关的技术，更拥有强大的开发和市场推广团队，为客户提供各类型的市场策略和对应的开发服务，用最合理的成本获得更大的回报。",
        subtitles7:"为配合大湾区和一带一路发展，无论国内和国外的企业都需要进一步优化生意流程和成本，拓展商机。",
        subtitles8:"我们拥有中国及国外的网络顾问经验，能为客户设计最合适的网络营销解决方案，",
        subtitles9:"让客户的业务获得更大的成功。",
    },
    net:{
        servesBigTitle0:"我们的服务",
        servesBigTitle1:"网络维护",
        servesTitle1:"网络监控",
        servesContent1:"监控网络流量、连接状态和效能，以确保网络运作正常。",
        servesTitle2:"安全性检查",
        servesContent2:"定期检查网络安全性，包括防火墙设定、入侵检测系统和安全漏洞，以确保网络不受到未授权访问或攻击。",
        servesTitle3:"数据备份和恢复",
        servesContent3:"建立定期的网络数据备份计划，确保重要数据能够在需要时快速恢复。",
        servesTitle4:"网络设备更新",
        servesContent4:"定期更新路由器、交换机和其他网络设备的软件，并检查硬件性能，以确保网络的高效运作。",
        servesTitle5:"网络性能优化",
        servesContent5:"优化网络设置，调整路由器和交换机的配置，以确保高效的数据传输和低延迟。",
        servesTitle6:"用户帐户管理",
        servesContent6:"管理用户帐户，包括创建、删除和修改账户权限，确保只有授权用户能够访问网络资源。",
        servesTitle7:"紧急应对计划",
        servesContent7:"制定应对网络故障和安全事件的计划，确保在网络问题发生时能够迅速应对和修复。",
        servesTitle8:"培训和意识提升",
        servesContent8:"为用户提供安全培训，提高他们的安全意识，降低网络威胁的风险。",
        servesBigTitle2:"计算机维护",
        servesTitle21:"硬件检测",
        servesContent21:"定期检查计算机硬件组件，确保良好的散热和运作效能。",
        servesTitle22:"软件更新和安全补丁",
        servesContent22:"安装操作系统、应用程序和防病毒软件的最新版本，同时确保所有安全补丁都被及时安装。",
        servesTitle23:"病毒和恶意软件扫描",
        servesContent23:"定期进行病毒和恶意软件扫描，以确保系统的安全性，并清除任何潜在的威胁。",
        servesTitle24:"数据备份和恢复",
        servesContent24:"建立定期的数据备份计划，确保重要数据能够在需要时快速恢复。",
        servesTitle25:"性能优化",
        servesContent25:"优化系统设置，提升计算机性能，并清除不必要的文件和应用程序，释放硬盘空间。",
        servesTitle26:"硬件更换和升级",
        servesContent26:"定期评估硬件性能，根据需求进行硬件升级或更换，以确保系统跟得上业务需求。",
        servesTitle27:"用户支持和问题解决",
        servesContent27:"提供用户支持服务，解决用户在使用计算机时遇到的问题，确保用户能够高效地使用系统。",
        reason0:"选择我们的原因",
        reason1:"计算机系统是企业运作的核心，包括数据储存、处理、通讯等功能。定期的维护可以确保系统稳定运行，减少故障发生的机会，确保业务连续性。",
        reason2:"网络安全问题日益严重，保养服务可以定期检测系统漏洞，安装最新的安全补丁，提高系统的抗攻击能力，保护重要数据不受侵害。",
        reason3:"定期的维护还可以延长计算机设备的寿命，降低企业的运营成本。总的来说，计算机维护及保养服务对于企业来说是一个非常重要且必要的投资。",
        set0:"服务套餐",
        set1:"远程服务",
        set2:"特急上门（按次）",
        set3:"月费服务",
        set4:"年费服务",
        set5:"远程",
        set6:"电话",
        set7:"到场",
        set8:"定期检查",
        set9:"无限",
        set10:"1次",
        set11:"1次/月",
    },
    tvp:{
        tvp00:"申请TVP科技券服务",
        tvp01:"最高60万 TVP科技券申请: 申请资格、审批时间及注意事项",
        tvp02:"资助金额最高60万",
        tvp03:"于2016年11月推出的「科技券」，旨在支持本地企业透过科技达至业务流程升级转型每间公司。TVP全年接受申请，最多可获批6个项目，合共最多可获60万港元。计划拨款以每一项目计算，以3:1的比例拨款 (政府:企业) ，即最多可由政府资助75%项目开支，其余25%则需由企业自行承担。需要留意的是，同一时间只接受一个项目申请，而每个项目必须于一年内完成。",
        tvp04:"什么企业符合TVP科技券申请资格?",
        tvp05:"「科技券」旨在支持本地企业透过引入科技配套，提高业务生产力、优化业务流程，甚至速成数码转型，从而提升竞争力。换言之，计划适合有意把业务转向数码化的老板申请。「科技券」涵盖的范围较广，吸引不同行业申请，较热门的申请行业包括物流业、零售业、餐饮业及会计业等，以下为TVP的基本申请资格：",
        tvp06:"1. 并非本港上市公司，亦并非政府资助机构或任何政府资助机构附属公司；及",
        tvp07:"2. 提交申请时在本港有实质业务运作，而该业务须与申请项目相关；及",
        tvp08:"3. 申请公司符合以下其中一项要求：",
        tvp09:"根据《商业登记条例》（第310章）在香港登记；或",
        tvp10:"根据《公司条例》（第622章）在香港注册成立的公司",
        tvp11:"根据有关条例在香港成立的法定机构",
        tvp12:"不同行业应用科技例子",
        tvp13:"不单是中小企，企业对科技的的应用场景十分广泛，哪些项目才符合申请资格亦很难一概而论，下表就为老板们提供各行业的科技应用例子，以及中小企申请「TVP科技券」的热门项目，供大家参考：",
        tvp14:"行业",
        tvp15:"科技应用例子",
        tvp16:"物流业",
        tvp17:"引入订单追踪系统，供员工更新货物运送记录及供客户查阅订单状态。",
        tvp18:"零售业",
        tvp19:"引进CRM系统，收集及分析会员大数据。",
        tvp20:"餐饮业",
        tvp21:"使用POS系统，食客使用手机扫码点餐。",
        tvp22:"会计业",
        tvp23:"应用云端/AI软件处理部分重复性运算工作，例如薪酬计算，同时掌握公司财政状况。",
        tvp24:"中小企热门「科技券」项目:",
        tvp25:"网店/APP/电子商贸平台",
        tvp26:"CRM客户关系管理系统",
        tvp27:"ERP 企业资源管理系统",
        tvp28:"POS 零售销售系统",
        tvp29:"HRM 人力资源管理系统",
        tvp30:"ChatBot 聊天机械人",
        tvp31:"Cloud Solution 云端技术",
        tvp32:"Database 数据库管理",
        tvp33:"WMS 仓存管理系统",
        tvp34:"TVP科技券申请程序一览",
        tvp35:"1. 向服务供货商索取报价单及已签署的诚信及不合谋报价/投标确认书",
        tvp36:"2. 透过科技券计划管理系统提交申请及附上所需文件",
        tvp37:"申请网址：",
        tvp38:"科技券计划管理系统 ：",
        tvp39:"申请表格样本：",
        tvp40:"科技券申请指南(所需文件请参阅附件3)：",
        tvp41:"3. 进行内部审核",
        tvp42:"一般审核标准",
        tvp43:"项目与申请者业务的关联性",
        tvp44:"预算及推行细节是否合理",
        tvp45:"技术顾问/服务供货商过往有否不良记录",
        tvp46:"4. 成功申请者与创新科技署签订资助协议",
        tvp47:"5. 按需要自行申请首期拨款",
        tvp470:"最高首期拨款可达资助额25%",
        tvp48:"6. 开展项目并于1年内完成及提交终期报告及审核账目",
        tvp49:"7. 创新科技署以书面形式通知申请者评审结果",
        tvp50:"8. 发放资助给获批者",
        tvp51:"如何申请TVP科技券首期拨款? ",
        tvp52:"很多企业在引入创新科技时，都希望有一笔钱作为前期费用及起动资金，其实「TVP科技券」亦可以申请首期拨款，最高可达项目获批资助额25%。以下是申请首期拨款的程序：",
        tvp53:"1. 在签订资助协议后提交首期拨款申请",
        tvp54:"2. 提供一个专为接收「科技券」的独立公司银行户口，即该户口只能用作处理相关项目的收入及开支",
        tvp55:"3. 存入指定金额至该银行户口 (根据3:1分布拨款原则存入同一百分比的款项)",
        tvp56:"4. 发放首期拨款给获批者",
        tvp57:"首期拨款的发放方式",
        tvp58:"首期拨款上限为项目获批资助额的 25%，并会在已确认申请者存入指定资金至公司银行户口后才发放。",
        tvp59:"举例:",
        tvp60:"假设项目获批资助额为40万元港币，当中30万元港币由政府资助，其余10万元港币则由申请者承担。申请者需按照3:1分布拨款比例先存入同一百分比的款项，即自行存入25,000元港币 (10万元港币的25%) ，然后提供存款证明。确认后才获发75,000元港币首期拨款 (即 30 万元的 25%) 。",
        tvp61:"申请时间: 申请最快何时获批?",
        tvp62:"创新科技署收到申请书后，会评估申请者资格及根据业务的相关性、预算和项目细节的合理性，以及查核技术顾问或服务供货商的过往记录。实际批核时间仍需视乎当时收到的申请书数量、个别申请的复杂程度及所提交的数据是否完整和清楚等；评估期间当局或会要求申请者作出澄清或提交补充数据，申请者必须于收到相关要求后一个月内提交文件。",
        tvp63:"终期拨款发放方面，申请者必须于项目完结后两个月内透过科技券计划管理系统向创新科技署提交下列文件:",
        tvp64:"点此前往科技券计划管理系统：",
        tvp65:"1. 项目最终报告",
        tvp66:"2. 项目成果证明 (例如顾问报告、交付收据、 软件系统的计算机截图 、 设备硬件的/照片 、举办培训的纪录，如照片及培训教材等)",
        tvp67:"3. 每个开支项目的付款发票及相应收据正本或副本，所显示的付款人名称必须与申请者名称相同",
        tvp68:"如果获批资金超过5万元港币，申请者须向创新科技署提交由合资格的独立审计师审核的开支报表；至于低于5万元港币，申请者只须提交最后开支报表并确认无误。",
        tvp69:"TVP科技券常见问题",
        tvp70:"我是否必须在资助审核成功后才可开展项目?",
        tvp71:"不是，你最早可于递交申请后翌日开始项目。",
        tvp72:"已开展的项目可以申请科技券资助吗?",
        tvp73:"可以，但申请者必须在不迟于实际项目开展日期后五个工作天向创新科技署提交填妥的「 声明及承诺书 」。你可前往",
        tvp74:"下载表格。",
        tvp75:"假如我在香港没有实际业务或业务大部分在内地/海外进行，我可以申请科技券资助吗?",
        tvp76:"不可以，两者皆不符合申请资格。",
        tvp77:"我名下有两间公司，其中一间已成功申请科技券资助，我可以再为另一间公司申请同一资助吗?",
        tvp78:"可以，每间公司最多可获批6个项目。",
        tvp79:"假如我已为一个科技项目成功申请了其他政府资助，可再申请科技券资助吗?",
        tvp80:"不可以，同一项目只可接受一个本地政府资助。",
        tvp81:"假如我在项目开展期间需要增加项目总成本，可以申请加大已获批项目的资助额吗?",
        tvp82:"不可以，任何修改项目范畴 (即获批准的科技方案)、或增加核准项目总成本、或增加项目的创新及科技基金资助总额的要求，将不获受理。",
        tvp83:"聘请服务供货商有什么要求?",
        tvp84:"你需要寻求外部的科技顾问/服务供货商，就项目提供技术服务或方案以满足资助要求，包括采购与项目有关的设备/硬件/软件服务。",
        tvp85:"申请科技券需要提供多少供货商报价?",
        tvp86:"可以，每间公司最多可获批6个项目。",
        tvp87:"申请科技券需要提供多少供货商报价?",
        tvp88:"项目价值",
        tvp89:"供货商报价数量",
        tvp90:"不超过50,000元港币",
        tvp91:"最少2间",
        tvp92:"超过50,000元港币但不多于 30 万元港币",
        tvp93:"最少3间",
        tvp94:"超过 30 万元港币但不多于 140 万元港币",
        tvp95:"最少5间",
        tvp96:"我可以使用现有公司户口申请科技券首期拨款吗?",
        tvp97:"不可以，你须开立一个全新公司户口用作处理与项目相关的收入及开支。",
        tvp98:"一般办公室设备、专业服务费或行政费用会否划入资助内?",
        tvp99:"不会，以下为不划入资助的例子",
        tvp100:"楼宇租金",
        tvp101:"员工薪金及其他相关开支，包括但不限于强制性公积金供款、约满 酬金、周年薪酬调整、一般附带福利(例如医疗)，以及津贴(例如房 屋、交通、逾时工作津贴)、一般培训及发展",
        tvp102:"日常营运所需的一般办公室设备／硬件、软件及科技服务或方案",
        tvp103:"现有及新购置设备的维修保养、保用及保险",
        tvp104:"非与科技相关的专业服务费",
        tvp105:"市场及品牌推广费用",
        tvp106:"交通和住宿",
        tvp107:"融资支出(例如支付贷款利息)",
        tvp108:"我是否可用现金支付项目费用?",
        tvp109:"可以，但每笔交易限于5,000元港币或以下。其他非现金支付方式，包括信用卡、支票及银行转账等则无特定金额限制。",
        tvp110:"立即咨询",
        tvp111:"问：",
        tvp112:"答：",
        url:require('@/assets/img/tvp-2-jt.png'),
    },
    edit:{
        edit01:"私人定制开发",
        edit02:"将客户每一个需求完美呈现",
        edit03:"模板千篇一律，但每一个企业都有自己的独门绝技。",
        edit04:"定制开发，深入挖掘客户的商业特色和真实需求。技术+匠心，精致打磨，让您的生意在互联网脱颖而出。",
        edit05:"20年经验",
        edit06:"成熟的网站规划策略和开发技术",
        edit07:"1对1服务",
        edit08:"中港精英工程师团队全程对接，急速响应",
        edit09:"服务全球客户",
        edit10:"贴合海外、香港及中国内地的推广规则",
        edit11:"一站式服务",
        edit12:"解决开发琐碎流程，服务一步到位",
        edit13:"量身定制式开发",
        edit14:"独家设计，极大程度提升品牌形象",
        edit15:"系统开发",
        edit16:"会员/项目/商城/OA/ERP/CRM/物流等系统",
        edit17:"各种软硬件开发，根据客户需求定制",
        edit18:"网站开发",
        edit19:"企业门户网站、外贸建站、商城网站、各行业建站",
        edit20:"响应式网站，多端适配；多语言，全球部署",
        edit21:"APP开发",
        edit22:"Android/iOS原生/混合/跨平台APP开发",
        edit23:"满足教育、电商、医疗、汽车等全行业APP开发的业务需求",
        edit24:"微信开发",
        edit25:"H5页面/微信公众号/服务/小程序等多种类型微信端应用定制开发",
        edit26:"结合用户社交、阅读和生活场景，坐享微信10亿流量，轻松叩响机遇大门",
        edit27:"中港精英工程师团队 · 1对1贴心服务",
        edit28:"中天科技20余年的海内外建站经历，在中港两地建立了一支经验丰富的精英工程师团队",
        edit29:"全程1对1服务，专人对接，保障沟通及时到位，技术过硬，高效解决需求",
        edit30:"100% 贴合客户需求",
        edit31:"网站定制开发",
        edit32:"响应式、多语言、全球部署，根据客户特点及需求，量身定制、优化",
        edit33:"我们的网站定制服务",
        edit34:"根据企业的特点和需求，量身定制",
        edit35:"定制开发一个功能、内容、设计更贴合企业的形象和业务需求的网站",
        edit36:"可以更好地展示企业的产品和服务，优化用户体验，凸显品牌形象，提升企业竞争力",
        edit37:"开发过程和成本灵活可控",
        edit38:"1v1全程对接，开发过程根据企业需求灵活调整，实现成本与时间的最优解",
        edit39:"性能和用户体验最大化",
        edit40:"量身定制，对网站安全性和性能进行更加精细的优化",
        edit41:"网站运行速度更快、稳定性更高、安全性更好",
        edit42:"根据网站目标用户的使用习惯进行设计",
        edit43:"操作更便捷，界面更友好，与用户快速建立信任感",
        edit44:"客制化的UI/UX设计",
        edit45:"用户体验（UX）和用户界面（UI）设计，能将网站的创意、功能和想法尽数展现。用心设计的UI和UX，能展现出企业优势，为用户留下更好的第一印象，留住潜在客户，创造更多可能性。",
        edit46:"多端适配的响应式网站",
        edit47:"响应式网站采用了弹性布局，能够根据访问设备的屏幕尺寸和解析度自动调整网站的外观和布局。多端适配，灵活显示，能为用户提供良好的跨设备使用体验，提高网站的可访问性，增加用户参与度和互动性，也更有利于搜索引擎优化。",
        edit48:"多语言全球化部署",
        edit49:"让网站访问者能不受语言和地理位置现实，享受同样流畅、直观的用户体验，为您量身定制的多语言网站，打造一个专属于您的全球门户，能助您的企业在全球同行中脱颖而出，获得全球用户的青睐。",
        edit50:"开发工具",
        edit51:"专为你定制设计的网站",
        edit52:"我们量身设计每一个细节，致力于为企业设计最贴合自身特点的网站。客制化的UI/UX设计，多端适配的响应式网站，多语言全球化部署，助力您的企业在数字化时代获得竞争优势及效益提升。\n",
        edit53:"一站式量身定制方案",
        edit54:"APP开发",
        edit55:"以手机为载体，拓展市场，创造新的营销机会，获取庞大商机",
        edit56:"定制开发APP的优势",
        edit57:"手机已经成为人们生活中不可或缺的一部分，移动互联网时代，人们的消费习惯也逐渐从线下转移到线上。定制一个专属的APP，方便挖掘移动市场的巨大潜力，让企业与品牌形象更加深入人心",
        edit58:"教育、电商、医疗、汽车，各行各业的手机APP，都能帮企业节省人力开支，增加收入来源，加强与顾客之间的联系。",
        edit59:"选择更适合你的APP类型",
        edit60:"中天动力20年来服务过数百家企业，为不同行业的企业定制了专属的APP。",
        edit61:"我们提供商城APP、仓存APP、MRP APP、CRM APP、OA APP，点餐APP，预约APP，以及其他的定制化APP服务，根据你的企业和品牌特色，量身打造。",
        edit62:"开发流程",
        edit63:"一站式服务，全程1对1专人对接，根据实际需求随时调整开发计划和流程，实现成本可控，过程透明，省时省心。",
        edit64:"需求沟通",
        edit65:"报价",
        edit66:"界面设计",
        edit67:"技术开发",
        edit68:"测试",
        edit69:"上线",
        edit70:"后期维护",
        edit71:"微信端应用定制开发",
        edit72:"微信小程序、H5页面、公众号、服务，多种微信端应用定制开发，10亿+微信日活跃用户，3亿+小程序日活跃使用者，",
        edit73:"搭乘顶流社交媒体顺风车，轻松叩响中国内地市场大门",
        edit74:"无需下载，不占内存，降低使用门槛",
        edit75:"依托微信生态，社交裂变效应，轻松触及目标用户",
        edit76:"扫码即用，转化率高，降低企业获客成本",
        edit77:"借力公众号、视频号，打造内容生态，提升企业形象",
        edit78:"小程序定制开发",
        edit79:"微官网、微商城、O2O平台、微外卖、微房产、微直播",
        edit80:"一站式服务，精英工程师全程1对1 对接，根据企业需求，量身定制专属小程序",
        edit81:"中天动力20年技术沉淀，提供个性化高品质定制服务，安全持续保障",
        edit82:"微信公众号代运营",
        edit83:"优质内容营销，全行业资源覆盖，快速传播品牌形象，提升影响力",
        edit84:"裂变式引流获客，全渠道广告推广，搭建私域矩阵",
        edit85:"微商城+客服系统搭建，精准营销，业绩持续增长",
        edit86:"社群搭建，建立清晰用户画像，促拓客导流",
        edit87:"小程序的行业机会"
    },
    about:{
        about01:"中天科技",
        about02:"网站开发/APP开发/小程序开发/系统开发/企业IT运维",
        about03:"优质创新的技术和服务",
        about04:"助力中港企业数字化、全球化",
        about05:"核心价值",
        about06:"技术",
        about07:"我们的本钱",
        about08:"用最先进的技术，保证高效性和可靠性",
        about09:"质量",
        about10:"我们的任务",
        about11:"严格控制开发过程，确保符合最高的开发标准",
        about13:"服务",
        about14:"我们的宗旨",
        about15:"全面的客户服务，快速的响应，专业的技术支援和售后服务",
        about16:"创新",
        about17:"我们的目标",
        about18:"紧随科技前沿，不断自我突破，开发新产品和解决方案",
        about19:"我们的服务",
        about20:"IT支援服务",
        about21:"网络与系统维护，降低企业成本，提高企业效率",
        about22:"定制开发",
        about23:"100%还原客户需求，1对1专人全程对接",
        about24:"网上商城",
        about25:"获得销售机会，增加销售渠道，触达潜在客户",
        about26:"系统开发",
        about27:"根据企业特点量身定制，契合企业需求，精准提升效益",
        about28:"网站开发",
        about29:"展示企业形象，传播品牌文化，提高品牌知名度",
        about30:"APP开发",
        about31:"挖掘移动市场巨大潜力，让品牌形象深入人心",
        about32:"微信开发",
        about33:"微信端应用开发+内容运营，借力社交媒体，让业务裂变式增长",
        about34:"观看视频",
        about35:"https://v.youku.com/v_show/id_XNDE5OTcwOTc0MA==.html",
    },
    dtspp: {
        dtspp1:'什么是数码转型支援先导计划 (DTSPP)？ ',
        dtspp2: '数码转型支援先导计划（下称「 本计划」），会以一对一（1:1）配对方式为本地小型及中型企业（下称「中小企」）提供资助，以协助中小企 应用即用型基本数码科技方案。 香港特别行政区政府（下称「政府」）已预留港币5亿元用于配对用途。 ' ,
        dtspp3: '申请本计划的拨款资助的中小企必须属于餐饮业或零售业（不包括餐饮业），并必须根据《商业登记条例》（第310章）在香港注册，并在香港有实质业务经营 。 它们不得是上市公司、法定机构或接受公帑资助的非政府机构。 ',
        dtspp4: '香港数码港管理有限公司（下称「HKCMCL」）是本计划的管理机构。 如对本指引或计划有任何疑问，请透过',
        dtspp5: '中列出的途径联络HKCMCL。 ',
        dtspp6: '目标',
        dtspp7: '本计划旨在透过为中小企提供一对一配对的拨款资助，支援其在目标方案类别中采用预先评估的现成、可用的数码科技方案，以实现加快中小企数码转型步伐的目标。 ',
        dtspp8: '该计划目标的方案类别如下：',
        dtspp9: '电子支付系统及店面销售',
        dtspp10: '协助企业自动化收款和计算的工作流程，并支援电子支付以开拓新收款渠道。 该类型的系统亦可包括自助式店面销售方案，如自助落单系统和自助售卖机等。 系统可透过营销数据自动生成报表，让企业分析其业务状况，为业务决策提供依据。 ',
        dtspp11: '线上推广',
        dtspp12: '为企业建设公司网站、社交媒体专页，或透过线上搜索引擎和社交媒体帮助他 们推广业务。 ',
        dtspp13: '客户管理及优惠系统',
        dtspp14: '该类型的系统可支援销售推广活动如电子会员计划和电子优惠券等。 系统亦可提供客户支援、个案管理及知识库等功能，并可提供报表让企业有效地检视、分析及管理销售活动、目标、所发掘的潜在客户及相关跟进工作，让企业能与现有及 潜在客户增加互动，并集中储存客户资料和联系记录。 ',
        dtspp15: '资助范围',
        dtspp16: '如果设备、硬件、软件和任何其他资产构成方案组合的重要组成部分，则允许运用资助以作购买、租赁或订阅，但须遵守以下规则：',
        dtspp17: '以本计划的资金助所购买的新设备、硬件、软件和任何其他资产的所有权和权益应归申请人所有；',
        dtspp18: '以本计划的资金助所购买、采购或租赁的所有物品应为持牌产品，且不得侵犯任何第三方的知识产权；和',
        dtspp19: '使用该设备、硬件、软件和任何其他资产的风险，将由申请人承担并由申请人保留。 ',
        dtspp20: '获批准的申请的方案应在申请获得批准后 9 个月内落实执行并准备好供申请人使用。 对于订阅形式的方案而言，允许在订阅期内提供最多两年的拨款资助。 ',
        dtspp21: '资助金额',
        dtspp22: '每位合资格申请人将按照方案清单所列的方案的费用，以一对一配对的方式，提供高达港币50,000元的资助。 申请人必须分担所采用的方案的总成本的至少一半。 ',
        dtspp23: '申请人的实物捐赠或任何非金钱代价将不被接受。 ',
        dtspp24: '获批准的申请项目（包括但不限于申请人或本计划资助的项目）不得接受其他本地公共资金来源的资助',
        dtspp25: '申请程序',
        dtspp26: '本计划将分批向申请人开放申请，每个申请批次将关于一个指定行业。 在后续的批次申请中，将于方案清单上陆续增加新的数码科技方案和方案类别，让申请者有更丰富的选择。 ',
        dtspp27: '每个申请批次的时间表和可供选择的数码科技方案类别，请见',
        dtspp28: '已提交申请且其申请仍有待批准的申请人，在其原先提交的申请被拒绝或撤回之前，不得再次提交申请。 ',
        dtspp29: '其申请已获批准的申请人，不得再次提出申请。 ',
        dtspp30: '在提交申请之前，申请人须先在本计划的网站登记一个申请人帐户。 申请人可以在方案清单中确定其想采用的方案，并直接与方案供应商联系，以了解该方案的功能并确定该方案是否满足其要求。 申请人须在相关的方案页面点击「查询」按钮使用本计划的网站，以向方案供应商确认是否可向申请人提供该方案组合。 在收到方案供应商确认后，申请人即可继续完成网上申请表。 ',
        dtspp31: '在此建议申请人，在选择他们想采用的方案之前，应了解和比较不同的方案和方案供应商。 每份申请只能选择一个方案组合。 ',
        dtspp32: '请注意，获列入方案清单，并不表示任何方案或方案供应商获得政府或HKCMCL的认可。 ',
        dtspp33: '申请人须透过本计划的网站提交其申请。 透过其他方式提交的申请（包括但不限于电子邮件或邮寄）均不获接纳。 ',
        dtspp34: '申请资格',
        dtspp35: '已根据《商业登记条例》（第310章）在香港注册；',
        dtspp36: '并非香港上市公司、法定机构或接受公帑资助的非政府机构；',
        dtspp37: '申请时在香港以下行业（「指定行业」）拥有实质业务运作：',
        dtspp38: '餐饮业',
        dtspp39: '零售业（不包括餐饮业）；',
        dtspp40: '全职员工少于50名',
        dtspp41: '不包括兼职员工',
        dtspp42: '对于以不同公司注册但属同一母公司下持有的公司，公司集团内的所有全职员工将被计算在内，且应少于50人，申请人才合资格参加此计划',
        dtspp43: '已批准申请或待批准申请的申请人不得再提交申请',
        dtspp44: '提交期间所需的资讯',
        dtspp45: '公司基本资料：',
        dtspp46: '公司名',
        dtspp47: '公司地址',
        dtspp48: '商业登记证号码',
        dtspp49: '行业类别',
        dtspp50: '主要联络人资讯（姓名、手机号码、电子邮件地址）',
        dtspp51: '选定方案',
        dtspp52: '证明文件（商业证明文件）',
        dtspp53: '提交文件',
        dtspp54: '商业证明',
        dtspp55: '申请时间表－资助申请人(SME)',
        dtspp56: '方案类别',
        dtspp57: '接受申请日期',
        dtspp58: '截止日期',
        dtspp59: '批次 #1(餐饮业方案申请)',
        dtspp60: '资助申请人可选方案类别:',
        dtspp61: '基础:',
        dtspp62: '电子支付系统及店面销售',
        dtspp63: '(可选)',
        dtspp64: '线上推广',
        dtspp65: '客户管理及优惠系统',
        dtspp66: '即将推出',
        dtspp67: '批次 #2(零售业方案申请)',
        dtspp68: '备注:',
        dtspp69: '逾期提交的申请将不予受理。 ',
        dtspp70: '申请开始时间为每批次接受申请日期的香港时间中午12时。 ',
        dtspp71: '截止申请时间为每批次截止申请日期的香港时间下午11时59分。 ',
        dtspp72: '申请程序',
        dtspp73: '步骤',
        dtspp74: '资助拨款给方案供应商',
        dtspp75: '金额 (占批准金额的百分比)',
        dtspp76: '条件',
        dtspp77: '阶段',
        dtspp78: '在申请人确认方案已实施并提供令人满意的证据（例如方案供应商的收据）表明其已就本计划的网站上所采用的方案支付相应份额的费用后，将向方案供应商 支付获批准拨款资助的50%的首期付款。 ',
        dtspp79: '申请获批准12个月后，资助申请人须递交总结报告及提交相关证明文件并获HKCMCL妥为接纳后才会发放剩余款项予方案供应商。 ',
        dtspp80: '商业登记证',
        dtspp81: '商业登记证有效商业登记证（注：商业登记证上的公司名称必须与报名提交资料上指定的公司名称相符）',
        dtspp82: '公司注册证书',
        dtspp83: '公司注册证书（注：公司注册证书上的公司名称必须与申请中列明的机构名称相符）',
        dtspp84: '声明',
        dtspp85: '由公司持有人/董事签署并加盖公司印章的申请人声明',
        dtspp86: '店面照片',
        dtspp87: '显示指定地址有大量且实质性业务的店面照片',
        dtspp88: '地址证明',
        dtspp89: '<p>以下文件之一，证明申请人在申请中指定的地址开展实质业务：</p>'
            +'<ul><li>过去3 个月内的电费帐单； 或</li><li>过去2 个季度的水费单； 或<li>租赁协议副本（租户姓名必须与申请中的 公司名称一致）</li></ul>',
        dtspp90: '合资格食环署牌照',
        dtspp91: '<p>*只限餐饮业</p>提供其中一份合资格食物环境卫生署牌照副本',
        dtspp92: '登记一个资助申请人帐户',
        dtspp93: '于网站中选择方案',
        dtspp94: '联系方案供应商并选择方案',
        dtspp95: '方案供应商在入口网站中表明“提供意向”',
        dtspp96: '在线提交申请',
        dtspp97: '检查资格和完整性',
        dtspp98: '提交评审委员会审批',
        dtspp99: '批准申请人向供应商下订单',
        dtspp100: '批准申请人向方案供应商下订单',
        dtspp101: '中小企业在门户网站中确认方案交付',
        dtspp102: '证明文件审批',
        dtspp103: '发放第一笔资助款项 (50%)',
        dtspp104: '中小企在门户网站提交总结报告',
        dtspp105: '检查报告的完整性和证明文件',
        dtspp106: '发放第二笔资助款项'
    },
    cloud:{
        cloud01:'Device Protect365 -',
        cloud0101:'云端备份',
        cloud02:'“这将颠覆云端产业——这是同类产品中的第一个，具有无限软件授权，可备份设备和 SaaS 服务，包括',
        cloud03:'Servers, Endpoints, Databases, VMware, Hyper-V, Synology, QNAP, Dropbox, Google Drive and Microsoft 365 的一体化解决方案”',
        cloud04:'Saaher Muzafer, Backup Everything的首席执行官.',
        cloud05:'电子邮件报告',
        cloud06:'每日通知',
        cloud07:'支援',
        cloud08:'免费和包含',
        cloud09:'软件授权',
        cloud10:'无需支付任何费用',
        cloud11:'储存',
        cloud12:'多个地区',
        cloud13:'介绍 Device Protect365',
        cloud14:'Device Protect365 是一款云端备份解决方案，透过自动增量备份保护您的数据，让您放心，如果发生数据问题，您将能够随时恢复数据。 好处是我们提供免费许可证，因此您可以备份无限的用户和计算器，此外我们还让您可以灵活地选择要储存数据的位置。',
        cloud16:'备份实体和虚拟服务器',
        cloud17:'保护您的 Windows 和 Mac 端点',
        cloud18:'备份数据库、Synology 和 QNAP',
        cloud19:'备份 Microsoft 365、Google Drive 和 Dropbox',
        cloud20:'来自 AWS、Azure、Google CP 等的备份储存',
        cloud21:'包含无限许可',
        cloud22:'规划自动备份和增量备份',
        cloud23:'快速轻松的恢复',
        cloud24:'Files & Folders',
        cloud25:'Windows',
        cloud26:'Mac',
        cloud27:'Linux',
        cloud28:'Windows System Backup',
        cloud29:'MS SQL',
        cloud30:'My SQL',
        cloud31:'Synology',
        cloud32:'Hyper-V',
        cloud33:'VMware',
        cloud34:'Oracle DB',
        cloud35:'Maria DB',
        cloud36:'QNAP',
        cloud37:'MS Exchange',
        cloud38:'MS Exchange (Granular)',
        cloud39:'M365 - Exchange',
        cloud40:'M365 - SharePoint',
        cloud41:'M365 - OneDrive',
        cloud42:'M365 - Teams',
        cloud43:'M365 - Groups',
        cloud44:'Wasabi',
        cloud45:'AWS',
        cloud46:'Azure',
        cloud47:'Google Cloud',
        cloud48:'Backblaze',
        cloud49:'Rackspace',
        cloud50:'Google Drive',
        cloud51:'Dropbox',
        cloud52:'FTP',
        cloud53:'SFTP',
        cloud54:'轻松数据恢复',
        cloud55:'透过我们友好的用户接口快速轻松地恢复您的数据',
        cloud56:'随着您的成长而扩展',
        cloud57:'随着您的成长添加、删除或扩展您的备份计划',
        cloud58:'客户支持',
        cloud59:'我们随时为您提供协助，让您的备份永远不会中断。',
        cloud60:'自动备份',
        cloud61:'自动定期备份以防止数据遗失',
        cloud62:'多个储存位置',
        cloud63:'选择将数据异地储存在多个区域或本地',
        cloud64:'领先的加密技术',
        cloud65:'256 AES 加密可保护您的飞行和静态数据',
        cloud66:'您可以将哪些内容备份到我们的云端和本地端？',
        cloud67:'安全与合规性',
        cloud68:'我们的解决方案基于最新的编码、安全性和合规性协议而构建,\n' +
            '因此请放心，您的数据将被安全存储，没有第三方存取。.',
        cloud69:'常见问题',
        cloud70:'问: Device Protect365 与其他提供者有何不同？',
        cloud71:'简单来说，这产品没有许可成本，并且仅以每 TB 为基础进行储存。 ',
        cloud72:'问: 使用你们的备份解决方案，我的数据的安全性如何？',
        cloud73:'我们使用业界领先的安全协议来确保您的数据在传输过程中和静态时都是安全的。 我们的数据中心位于具有 24/7 监控的安全设施中，我们使用加密来保护您的数据免受网络威胁。',
        cloud74:'问: 设定和管理所有解决方案是否容易？',
        cloud75:'是的！ 我们的备份解决方案設計容易操作，具有简单直觉的接口，不需要特殊的技术知识。 此外，我们也提供客户支持，协助您解决可能遇到的任何疑问或问题。',
        cloud76:'问: 你们的备份解决方案可以随着我的业务扩展吗？',
        cloud77:'是的！ 我们的云端备份解决方案旨在与您的业务一起发展，因此您可以根据需要轻松新增或删除资料。 此外，我们灵活的定价选项可让您随着业务的发展轻松扩大或缩小规模。',
        cloud78:'问: 你们的解决方案多久备份一次我的资料？',
        cloud79:'您可以自定义备份计划以满足您的业务需求。 选择您希望备份数据的频率和时间。',
        cloud80:'问: 如果发生数据遗失，我可以多快恢复数据？',
        cloud81:'我们的界面可让您在数据遗失时轻松快速地恢复数据。 对于装置解决方案，最好建立本机副本，以便您也可以从 LAN 速度复原。',
        cloud82:'问: 你们支持哪些平台和操作系统？',
        cloud83:'我们可以备份各种平台和操作系统，包括 Windows、macOS 和 Linux。 另外还有 MS SQL、MySQL、VMware、Hyper-V、Synology 和 QNAP。',
    }


}

