export default {
    menu:{
        about:"關於我們",
        customize:"定制開發",
        website:"網站開發",
        app:"APP開發",
        wechat:"微信開發",
        store:"網上商城",
        it:"IT支援服務",
        contact:"聯繫我們",
        tvp:"申請科技券服務",
        funding:"申請資助",
        DTSPP:"數碼轉型支援先導計劃",
        quotation:"獲取報價",
        consultation:"立即咨詢",
        cloud:"雲備份",
    },
    index:{
        welcome:"歡迎",
        come:"來到",
        name:"中天科技系統有限公司",
        more:"深入了解",
        customize0:"訂製開發您獨一無二的系統",
        customize1:"固定資產系統",
        customize2:"貸款系統",
        customize3:"QC驗貨系統",
        customize4:"補習社系統",
        customize5:"批發系統",
        customize6:"CRM系統",
        customize7:"迷你倉系統",
        customize8:"網上商城",
        customize9:"ERP系統",
        customize10:"預約系統",
        customize11:"排隊系統",
        customize12:"MRP系統",
        customize13:"貿易系統",
        customize14:"倉存系統",
        customize15:"OA系統",
        customize16:"裝修平臺",
        customize17:"其它",
        website1:"網站定制開發",
        website2:"網站定制開發是根據客戶需求打造獨一無二的網站解決方案，提供個性化、靈活性和可擴展性",
        app0:"根據客戶需求定制開發獨特的APP",
        app1:"預約系統APP",
        app2:"倉存APP",
        app3:"補習社APP",
        app4:"MRP APP",
        app5:"迷你倉APP",
        app6:"CRM APP",
        app7:"會員APP",
        app8:"OA APP",
        app9:"商城APP ",
        wechat1:"微信定制開發",
        wechat2:"微信開發，創造無限可能！公眾號、小程序、企業微信，提供個性化互動和業務擴展",
        mall1:"多國貨幣",
        mall2:"多種語言",
        mall3:"贈劵折扣功能",
        mall4:"購物車",
        mall5:"商品搜尋",
        mall6:"線上付款功能",
        mall7:"會員系統",
        mall8:"翻查購買紀錄",
        it1:"我們是領先業界的IT支援服務提供商，為您提供全方位的技術支持和解決方案。",
        it2:"我們的專業團隊會根據您的需求，提供個人化的IT支援服務，確保您的系統和設備的順利運作。",
        it3:"無論是即時支援還是定期維護，我們都致力於為您提供無憂的IT體驗，讓您專注於業務發展。",
        thank:"謝謝你的查詢，我們會盡快與你聯絡",
    },
    footer:{
        company1:"中天科技系統有限公司",
        company2:"中天動力系統(深圳)有限公司",
        address:"地址",
        address1:"沙田火炭㘭背灣街53-55號美高工業大廈3/F D38室",
        address2:"深圳市羅湖區江背路8號廬山大廈C座17A11室",
        mail:"電郵",
        // mail1:"info@chinaskynet.net",
        tel1:"(852) 24902300",
        tel2:"(0755) 25100512",
        map:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1844.402473348265!2d114.1933091!3d22.3987095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34040634eb551603%3A0x6f45e5cf90879d4!2z54Gr54Kt5Z2z6IOM54Gj6KGXNTPomZ_nvo7pq5jlt6Xmpa3lpKflu4g!5e0!3m2!1szh-TW!2shk!4v1743059071030!5m2!1szh-TW!2shk"
        // copyright:"Copyright @ 2023 China Sky Technology System Limited. All rights reserved",
    },
    form:{
        placeholderName: '請輸入姓名',
        placeholderTel: '請輸入聯絡電話',
        placeholderEmail: '請輸入電郵地址',
        placeholderText: '請輸入留言',
        placeholderEmail2: '請輸入正確電郵地址',
        name: "姓名：",
        mail: "電郵：",
        tel: "電話：",
        message: "留言：",
        submit: "提交",
        successText: '驗證通過！',
        failText: '驗證失敗，請重試！',
        sliderText: "向右滑動",
        titles: "留言",
        titleText:"如果您有任何疑問，請聯絡我們，我們很高興能為您解答疑問",
        tips:"星號為必填項",
        online:"租用網上商城"
    },
    project: {
        caseTit: '我們的案例',
        JiaZaiGD: '查看更多',
        ChaKan: '查看'
    },
    page:{
        quotation: "免費即時報價",
        getQuotation:"更多系统定制開發，請於我們聯繫",
        customizeName0: "系統開發",
        customizeName1: "產品開發",
        customizeIntro1: "中天科技是一站式的電腦程式設計外包服務商:需求分析、定制解決方案、體系結構、UI/UX設計、開發、測試、部署、維護、系統管理和支持",
        customizeName2: "專業團隊",
        customizeIntro2: "我們會反復檢查您的目標及需求，並為您提供最適合實施方案",
        customizeName3: "現有系統支持及升級",
        customizeIntro3: "我們提供將現有系統支持及升級到新興技術，同時亦保留原有的數據",
        customizeName4: "除錯服務",
        customizeIntro4: "除我們提供遠端除錯服務，不管相隔多遠，我們的團隊都會像在你身旁與你溝通",
        customizeName5: "支持與維護",
        customizeIntro5: "客戶可以通過電話或電子郵件跟我們的軟件工程師溝通，我們的軟件工程師會作出最快的響應，並修復代碼中的錯誤",
        customizeName6: "代碼審查服務",
        customizeIntro6: "將徹底檢查現有產品的代碼，以確保其可靠性、可維護性、效率和安全性方面符合最佳狀況無論您的企業規模如何，我們都能夠提供高效的IT支持和解決方案，針對您的具體需求定制服務。立即聯繫我們，獲取更多信息，並獲得有關我們的Help Desk和其他IT服務的免費報價。讓我們幫助您擺脫IT困擾，專注於核心業務的發展",
        customizeTitle: "度身訂製專屬於您的系統",
        customizeName7: "貸款系統",
        customizeName71: "客戶資料模組",
        customizeName72: "貸款管理模組",
        customizeName73: "還款管理模組",
        customizeName74: "客戶資料模組",
        customizeName8: "貿易系統",
        customizeName81: "業務模組",
        customizeName82: "採購模組",
        customizeName83: "庫存模組",
        customizeName84: "財務模組",
        customizeName9: "MRP生產管理系統",
        customizeName91: "銷售模組",
        customizeName92: "生產模組",
        customizeName93: "採購模組",
        customizeName94: "質檢模組",
        customizeName95: "倉存模組",
        customizeName10: "批發系統",
        customizeName101: "設置零售商",
        customizeName102: "設置各種水果名稱及價錢",
        customizeName103: "生成訂單",
        customizeName104: "整合總訂單數量",
        customizeName105: "生成出貨單及帳單",
        customizeName11: "裝修平臺",
        customizeName111: "用戶註冊及登入",
        customizeName112: "設置可提供之服務",
        customizeName113: "師傅註冊及登入",
        customizeName114: "管理員審批師傅",
        customizeName115: "用戶下單",
        customizeName116: "師傅接單",
        customizeName117: "完工後付款及用戶對師傅評價",
        customizeName12: "固定資產系統",
        customizeName121: "用戶註冊及登入",
        customizeName122: "設置可提供之服務",
        customizeName123: "師傅註冊及登入",
        customizeName124: "管理員審批師傅",
        customizeName125: "用戶下單",
        customizeName126: "師傅接單",
        customizeName127: "完工後付款及用戶對師傅評價",
        customizeName13: "補習社系統",
        customizeName131: "用戶註冊及登入",
        customizeName132: "設置免費試用期",
        customizeName133: "分中、小學生制",
        customizeName134: "小學生設有每日上傳家課冊功能",
        customizeName135: "即問即答功能–線上問功課",
        customizeName136: "不同科目之補充練習發放",
        customizeName137: "購買會員及續費服務",
        customizeName14: "迷你倉系統",
        customizeName141: "線上付款",
        customizeName142: "用戶下單及預約送箱",
        customizeName143: "用戶拍照物件",
        customizeName144: "設置每種不同收費",
        customizeName145: "線管理員清楚看到每張訂單的詳細資料上付款",
        customizeName146: "送箱人員收到送箱訂單及聯絡人資料",
        customizeName147: "系統每日提示到期客戶訂單",
        customizeName148: "用戶註冊及登入",
        customizeName15: "迷你倉系統",
        customizeName151: "開報價單、保留不同版本報價單",
        customizeName152: "開Invoice及保存客戶確認之報價單",
        customizeName153: "生成應收應付帳",
        customizeName154: "員工上班打卡，每月計算工資",
        customizeName155: "請假申請，看到自己的剩餘年假及審批狀態",
        customizeName156: "報銷申請，看到審批狀態",
        customizeName16: "QC驗貨系統",
        customizeName161: "從SAP系統中抽取所需要的驗貨單資料導入到APP中",
        customizeName162: "管理員在APP把驗貨單分配到相關的驗貨員帳號",
        customizeName163: "驗貨員登入可看到各自需負責的驗貨單資料、驗貨日期及工廠地址等資料",
        customizeName164: "驗貨員驗貨拍照及填寫驗貨資料，驗貨後工廠負責人可在APP中簽名，系統生成驗貨報告",
        customizeName165: "用戶可將驗貨報告發送email",
        quotationNeed: "獲取報價",
        bottom:"迅速報價，優質方案",
        website1:"響應式網站和傳統網站對比",
        website2:"響應式網站和傳統網站是兩種不同的網站設計方法，它們在設計和使用上有一些重要的區別。響應式網站則採用了彈性佈局，能夠根據訪問設備的屏幕尺寸和解析度自動調整網站的外觀和佈局。",
        websiteName3:"響應式網站",
        websiteTxt311:"設計方式：",
        websiteTxt312:"響應式網站則採用了彈性佈局，能夠根據訪問設備的屏幕尺寸和解析度自動調整網站的外觀和佈局。",
        websiteTxt321:"使用體驗：",
        websiteTxt322:"響應式網站能夠提供更好的使用體驗，因為它能夠適應各種設備，包括桌面電腦、平板電腦和手機等。不論用戶使用何種設備訪問網站，都能獲得良好的瀏覽體驗。",
        websiteTxt331:"開發成本：",
        websiteTxt332:"響應式網站通常需要額外的開發工作，因為它需要根據不同的屏幕尺寸和解析度調整佈局和設計。這意味著開發響應式網站可能需要更多的時間和資源。",
        websiteTxt341:"SEO 優化：",
        websiteTxt342:"由於響應式網站提供了一個統一的 URL 和 HTML 代碼，對於搜索引擎優化（SEO）來說更為有利。",
        websiteName4:"傳統網站",
        websiteTxt41:"傳統網站通常是針對桌面電腦的設計，使用固定尺寸和佈局。",
        websiteTxt42:"傳統網站在較小的屏幕上可能需要水平滾動或縮放以便查看內容，使用體驗不如響應式網站便捷。",
        websiteTxt43:"傳統網站只需要設計一個固定的版本，相對來說較為簡單。",
        websiteTxt44:"傳統網站如果有多個版本（例如桌面版和移動版），可能需要額外的 SEO 努力來確保每個版本都能獲得良好的排名。",
        websiteName5:"多終端顯示的優勢",
        websiteTxt5:"提供良好的跨設備使用體驗。提高網站的可訪問性。增加用戶參與度和互動性。有利於搜索引擎優化。",
        appName1:"原生APP",
        appTxt1:"原生APP是專為特定平台（例如iOS、Android）使用原生程式語言（例如Objective-C/Swift、Java/Kotlin）開發的應用程式。它們能夠完全利用平台的功能和性能，提供最佳的使用者體驗。原生APP通常需要單獨開發和維護，並且需要分別針對不同平台進行開發。總的來說，原生APP提供最佳的使用者體驗，但需要單獨針對不同平台進行開發。",
        appName2:"混合APP",
        appTxt2:"混合APP是使用Web技術（HTML、CSS、JavaScript）開發的應用程式，然後使用框架（如React Native、Ionic）將其封裝為原生應用程式。混合APP可以跨平台運行，並且可以重用部分程式碼，從而節省開發成本和時間。然而，它們可能無法完全擁有原生APP的性能和功能。總的來說，混合APP具有跨平台的優勢，但可能欠缺原生APP的性能。",
        appName3:"Web APP",
        appTxt3:"Web APP是使用Web技術（HTML、CSS、JavaScript）開發的應用程式，可以在網頁瀏覽器中運行。Web APP不需要進行安裝，用戶可以通過瀏覽器直接訪問。它們具有跨平台性和即時更新的優勢，但可能受到網路連接和瀏覽器功能的限制。總的來說，Web APP具有跨平台和即時更新的優勢，但在功能和性能方面可能受到限制。",
        wechatName1:"微信小程序",
        wechatTxt1:"微信小程序是在微信平台上運行的輕量級應用程式，用戶可以在微信中直接使用，不需要額外的安裝。它們提供了各種功能，包括線上購物、社交媒體、生活服務等。微信小程序開發使用的是小程序專用框架（如微信原生框架、Taro、uni-app），可以實現跨平台運行，並且具有較好的用戶體驗和性能。總的來說，微信小程序是在微信平台上運行的輕量級應用程式。",
        wechatName2:"微信公眾號",
        wechatTxt2:"微信公眾號是一種基於微信平台的官方帳號，用戶可以訂閱並接收公眾號發布的訊息、文章、圖片、視頻等。它們通常由企業、組織、名人等擁有和運營，用於品牌推廣、信息傳遞、客戶互動等目的。微信公眾號可以分為訂閱号和服務号兩種類型，功能和權限有所區別。總的來說，微信公眾號是官方帳號用於信息傳遞和品牌推廣。",
        wechatName3:"企業微信",
        wechatTxt3:"企業微信是針對企業內部使用的企業通訊和協作平台，類似於企業版的微信。它提供了企業內部通訊、組織架構、工作流程、應用程式集成等功能，用於加強企業內部溝通和協作。企業微信還支持企業應用程式的開發和定制，使企業能夠根據自身需求擴展功能。總的來說，企業微信則是企業內部通訊和協作平台。",
        mall1:"探索無界購物體驗：網上商城帶您進入全新電子商務世界",
        mall111:"商品管理",
        mall2:"購物車功能",
        mall3:"收藏",
        mall4:"營銷活動",
        mall5:"訂單管理",
        mall6:"登錄/註冊",
        mall7:"會員中心",
        mall8:"售後退款",
        mall9:"租用限時優惠",
        mall10:"網站+APP商城（iOS+Android）",
        mall11:"年",
        mall12:"購買",
        mall13:"購買包含",
        mall14:"3種語言版本（繁、簡、英）",
        mall15:"含一年雲端空間10G空間及每月50G流量",
        mall16:"優惠價-第二年開始收取HKD 2,900.00元（雲端空間及維護費用-含同樣的服務即10G空間及每月50G流量，系統除錯服務及app版本更新)",
        mall17:"第三年開始取正價HKD 5,800.00元（雲端空間及維護費用-含同樣的服務即10G空間及每月50G流量，系統除錯服務及app版本更新)",
        mall19:"只接入最多4種支付方式，多於4種會額外收取費用",
        it0:require('@/assets/img/IT_pic01-hk.png'),
        it1:"服務範圍覆蓋中港兩地",
        itName1:"資料備份和保護",
        itTxt1:"資料備份和保護是將數據複製到另一個位置或儲存媒體，以防止數據丟失、損壞或未授權訪問。它包括定期備份數據、制定備份策略、加密數據、監控和測試備份系統的有效性。這些措施確保數據的可靠性和安全性，並提供應對數據災害的準備。",
        itName2:"桌面維護",
        itTxt2:"桌面維護是管理和支持計算機桌面環境的活動，包括系統監控、軟件安裝、病毒防護、數據備份和用戶支持。它旨在提供穩定、安全和高效的桌面工作環境，確保用戶順利運作和數據安全。",
        itName3:"電腦技術支援",
        itTxt3:"電腦技術支援是提供解決電腦問題和技術支持的服務，包括軟件安裝、故障排除、病毒防護、網絡連接、硬件維修和用戶支持等。",
        itTxt31:"通常，電腦技術支援可以通過多種方式提供，包括遠程支援、電話支援、在場支援和線上資源。",
        itTxt32:"簡而言之，電腦技術支援是提供解決電腦和技術問題的服務，幫助用戶順利使用並維護他們的系統。它是確保順利運行並充分利用電腦技術的關鍵一環。",
        itName4:"IT維護",
        itTxt4:"IT維護是指對信息技術（IT）基礎設施和系統進行管理、保養和支持的一系列活動。它旨在確保IT資源的穩定運行，提供高效、安全和可靠的IT環境。",
        itTxt41:"目標是確保IT系統和資源的正常運行，最大程度地減少系統故障和數據損失的風險，提高組織的生產力和效率。它是企業和組織中IT部門的重要職能之一，有助於確保IT資源的可用性、安全性和可靠性。",
        itName5:"網路管理",
        itTxt5:"網路管理是指對計算機網絡基礎設施進行有效管理和監控的活動。它涵蓋了網絡設備、連接、資源和安全性等方面的管理。",
        itTxt51:"主要目標是確保網絡的可用性、性能和安全性，以滿足組織的需求並支持業務運作。",
        itTxt52:"確保網絡運作順利、安全可靠的關鍵一環。它有助於提高網絡的效能、降低故障風險，並提供良好的用戶體驗。組織和企業可以借助網路管理來管理和維護他們的網絡基礎設施，以支持業務的順利運作。",
        itName6:"伺服器管理",
        itTxt6:"伺服器管理是指對伺服器硬件和软体進行有效管理和監控的一系列活動。它包括伺服器的配置、安裝、維護和監測，以確保伺服器的正常運行和高效性能。",
        itTxt61:"主要目標是確保伺服器的可用性、安全性和效能，以滿足組織的需求並支持業務運作。",
        itTxt62:"確保伺服器運行順利、安全可靠的重要一環。它有助於提高伺服器的效能、減少故障風險，並確保資料的完整性和可用性。組織和企業可以通過有效的伺服器管理，確保其業務系統的穩定運行，並滿足不斷變化的需求。",
        itReason:"選擇CHINA SKY的理由",
        itReason1:"專業技術：擁有豐富的IT技術知識和經驗。",
        itReason2:"個人化服務：提供量身定制的解決方案，關注客戶的獨特需求。",
        itReason3:"及時響應：快速響應並提供解決方案，減少停工時間。",
        itReason4:"綜合解決方案：提供全方位的IT支援服務，節省時間和精力。",
        itReason5:"信任和信譽：擁有良好的信譽和口碑，與眾多客戶建立長期合作關係。",
        rentBtn: "租用",
        //新增
        look: "查看案例",
        addStore01:"中天科技系統週年慶",
        addStore02:"網上商城租賃",
        addStore03:"驚爆價！！",
        addStore04:"HKD",
        addStore05:"年",
        addStore06:"包含網站+APP商城（iOS+Android)",
        addStore07:"原价：HKD 22,800.00/年",
        addStore08:"立即租用",
        addStore09:"網上商城租賃服務",
        addStore10:"我們的網上商城租賃服務，為您免去昂貴的自主開發費用，節約您的開發時間，讓您的業務儘快開展，服務包含網站與App，各類商品類型均可通用，一站式打造您的網上商城。",
        addStore11:"會員系統",
        addStore12:"商品系統",
        addStore13:"購物車",
        addStore14:"訂單系統",
        addStore15:"線上支付",
        addStore16:"報表匯出功能",
        addStore17:"折扣優惠功能",
        addStore18:"積分抵扣現金功能",
        addStore19:"支持繁、簡、英三種語言",
        addStore20:"一年雲端空間10GB,每月50G流量",
        addStore21:require('@/assets/img/ad-hk.png'),
    },
    video:{
        url:require('@/assets/img/video-hk.mp4'),
        subtitles1:"現今網絡世界的進化速度驚人,為了讓客戶感到更親切和方便，企業必須擁有完善的IT系統和網絡平台。",
        subtitles2:"中天科技系統有限公司，於2004年在香港成立，同年於深圳開設分公司。",
        subtitles3:"我們一直為香港和內地的企業提供IT外包服務：包括固定檢查、網路管理、網路安全、VPN解決方案等。",
        subtitles4:"由於我們於中港均有專業的技術人員，所以不論客戶於香港或內地遇上IT問題都可以提供最即時和合適的支援。",
        subtitles5:"近年網絡行銷為企業提供了全新的商業機會，社交平台的相關程式和服務成為了各企業強大的工具。",
        subtitles6:"中天科技對每個項目由設計，開發及線上推廣，都掌握了全面相關的技術，更擁有強大的開發和市場推廣團隊，為客戶提供各類型的市場策略和對應的開發服務，用最合理的成本獲得更大的回報。",
        subtitles7:"為配合大灣區和一帶一路發展，無論國內和國外的企業都需要進一步優化生意流程和成本，拓展商機。",
        subtitles8:"我們擁有中國及國外的網絡顧問經驗，能為客戶設計最合適的網絡營銷解決方案，",
        subtitles9:"讓客戶的業務獲得更大的成功。",
    },
    net:{
        servesBigTitle0:"我們的服務",
        servesBigTitle1:"網絡維護",
        servesTitle1:"網絡監控",
        servesContent1:"監控網絡流量、連接狀態和效能，以確保網絡運作正常。",
        servesTitle2:"安全性檢查",
        servesContent2:"定期檢查網絡安全性，包括防火牆設定、入侵檢測系統和安全漏洞，以確保網絡不受到未授權訪問或攻擊。",
        servesTitle3:"數據備份和恢復",
        servesContent3:"建立定期的網絡數據備份計劃，確保重要數據能夠在需要時快速恢復。",
        servesTitle4:"網絡設備更新",
        servesContent4:"定期更新路由器、交換機和其他網絡設備的軟件，並檢查硬件性能，以確保網絡的高效運作。",
        servesTitle5:"網絡性能優化",
        servesContent5:"優化網絡設置，調整路由器和交換機的配置，以確保高效的數據傳輸和低延遲。",
        servesTitle6:"用戶帳戶管理",
        servesContent6:"管理用戶帳戶，包括創建、刪除和修改帳戶權限，確保只有授權用戶能夠訪問網絡資源。",
        servesTitle7:"緊急應對計劃",
        servesContent7:"制定應對網絡故障和安全事件的計劃，確保在網絡問題發生時能夠迅速應對和修復。",
        servesTitle8:"培訓和意識提升",
        servesContent8:"為用戶提供安全培訓，提高他們的安全意識，降低網絡威脅的風險。",
        servesBigTitle2:"電腦維護",
        servesTitle21:"硬件檢測",
        servesContent21:"定期檢查電腦硬件組件，確保良好的散熱和運作效能。",
        servesTitle22:"軟件更新和安全補丁",
        servesContent22:"安裝操作系統、應用程式和防毒軟件的最新版本，同時確保所有安全補丁都被及時安裝。",
        servesTitle23:"病毒和惡意軟件掃描",
        servesContent23:"定期進行病毒和惡意軟件掃描，以確保系統的安全性，並清除任何潛在的威脅。",
        servesTitle24:"數據備份和恢復",
        servesContent24:"建立定期的數據備份計劃，確保重要數據能夠在需要時快速恢復。",
        servesTitle25:"性能優化",
        servesContent25:"優化系統設置，提升電腦性能，並清除不必要的文件和應用程式，釋放硬盤空間。",
        servesTitle26:"硬件更換和升級",
        servesContent26:"定期評估硬件性能，根據需求進行硬件升級或更換，以確保系統跟得上業務需求。",
        servesTitle27:"用戶支援和問題解決",
        servesContent27:"提供用戶支援服務，解決用戶在使用電腦時遇到的問題，確保用戶能夠高效地使用系統。",
        reason0:"選擇我們的原因",
        reason1:"電腦系統是企業運作的核心，包括數據儲存、處理、通訊等功能。定期的維護可以確保系統穩定運行，減少故障發生的機會，確保業務連續性。",
        reason2:"網絡安全問題日益嚴重，保養服務可以定期檢測系統漏洞，安裝最新的安全補丁，提高系統的抗攻擊能力，保護重要數據不受侵害。",
        reason3:"定期的維護還可以延長電腦設備的壽命，降低企業的運營成本。總的來說，電腦維護及保養服務對於企業來說是一個非常重要且必要的投資。",
        set0:"服務套餐",
        set1:"遠程服務",
        set2:"特急上門（按次）",
        set3:"月費服務",
        set4:"年費服務",
        set5:"遠程",
        set6:"電話",
        set7:"到場",
        set8:"定期檢查",
        set9:"無限",
        set10:"1次",
        set11:"1次/月",
    },
    tvp:{
        tvp00:"申請TVP科技券服務",
        tvp01:"最高60萬 TVP科技券申請: 申請資格、審批時間及注意事項",
        tvp02:"資助金額最高60萬",
        tvp03:"於2016年11月推出的「科技券」，旨在支持本地企業透過科技達至業務流程升級轉型每間公司。TVP全年接受申請，最多可獲批6個項目，合共最多可獲60萬港元。計劃撥款以每一項目計算，以3:1的比例撥款 (政府:企業) ，即最多可由政府資助75%項目開支，其餘25%則需由企業自行承擔。需要留意的是，同一時間只接受一個項目申請，而每個項目必須於一年內完成。",
        tvp04:"什麼企業符合TVP科技券申請資格?",
        tvp05:"「科技券」旨在支持本地企業透過引入科技配套，提高業務生產力、優化業務流程，甚至速成數碼轉型，從而提升競爭力。換言之，計劃適合有意把業務轉向數碼化的老闆申請。「科技券」涵蓋的範圍較廣，吸引不同行業申請，較熱門的申請行業包括物流業、零售業、餐飲業及會計業等，以下為TVP的基本申請資格：",
        tvp06:"1. 並非本港上市公司，亦並非政府資助機構或任何政府資助機構附屬公司；及",
        tvp07:"2. 提交申請時在本港有實質業務運作，而該業務須與申請項目相關；及",
        tvp08:"3. 申請公司符合以下其中一項要求：",
        tvp09:"根據《商業登記條例》（第310章）在香港登記；或",
        tvp10:"根據《公司條例》（第622章）在香港註冊成立的公司",
        tvp11:"根據有關條例在香港成立的法定機構",
        tvp12:"不同行業應用科技例子",
        tvp13:"不單是中小企，企業對科技的的應用場景十分廣泛，哪些項目才符合申請資格亦很難一概而論，下表就為老闆們提供各行業的科技應用例子，以及中小企申請「TVP科技券」的熱門項目，供大家參考：",
        tvp14:"行業",
        tvp15:"科技應用例子",
        tvp16:"物流業",
        tvp17:"引入訂單追蹤系統，供員工更新貨物運送記錄及供客戶查閱訂單狀態。",
        tvp18:"零售業",
        tvp19:"引進CRM系統，收集及分析會員大數據。",
        tvp20:"餐飲業",
        tvp21:"使用POS系統，食客使用手機掃碼點餐。",
        tvp22:"會計業",
        tvp23:"應用雲端/AI軟件處理部分重複性運算工作，例如薪酬計算，同時掌握公司財政狀況。",
        tvp24:"中小企熱門「科技券」項目:",
        tvp25:"網店/APP/電子商貿平台",
        tvp26:"CRM客戶關係管理系統",
        tvp27:"ERP 企業資源管理系統",
        tvp28:"POS 零售銷售系統",
        tvp29:"HRM 人力資源管理系統",
        tvp30:"ChatBot 聊天機械人",
        tvp31:"Cloud Solution 雲端技術",
        tvp32:"Database 數據庫管理",
        tvp33:"WMS 倉存管理系統",
        tvp34:"TVP科技券申請程序一覽",
        tvp35:"1. 向服務供應商索取報價單及已簽署的誠信及不合謀報價/投標確認書",
        tvp36:"2. 透過科技券計劃管理系統提交申請及附上所需文件",
        tvp37:"申請網址：",
        tvp38:"科技券計劃管理系統 ：",
        tvp39:"申請表格樣本：",
        tvp40:"科技券申請指南(所需文件請參閱附件3)：",
        tvp41:"3. 進行內部審核",
        tvp42:"一般審核標準",
        tvp43:"項目與申請者業務的關聯性",
        tvp44:"預算及推行細節是否合理",
        tvp45:"技術顧問/服務供應商過往有否不良記錄",
        tvp46:"4. 成功申請者與創新科技署簽訂資助協議",
        tvp47:"5. 按需要自行申請首期撥款",
        tvp470:"最高首期撥款可達資助額25%",
        tvp48:"6. 開展項目並於1年內完成及提交終期報告及審核賬目",
        tvp49:"7. 創新科技署以書面形式通知申請者評審結果",
        tvp50:"8. 發放資助給獲批者",
        tvp51:"如何申請TVP科技券首期撥款? ",
        tvp52:"很多企業在引入創新科技時，都希望有一筆錢作為前期費用及起動資金，其實「TVP科技券」亦可以申請首期撥款，最高可達項目獲批資助額25%。以下是申請首期撥款的程序：",
        tvp53:"1. 在簽訂資助協議後提交首期撥款申請",
        tvp54:"2. 提供一個專為接收「科技券」的獨立公司銀行戶口，即該戶口只能用作處理相關項目的收入及開支",
        tvp55:"3. 存入指定金額至該銀行戶口 (根據3:1分佈撥款原則存入同一百分比的款項)",
        tvp56:"4. 發放首期撥款給獲批者",
        tvp57:"首期撥款的發放方式",
        tvp58:"首期撥款上限為項目獲批資助額的 25%，並會在已確認申請者存入指定資金至公司銀行戶口後才發放。",
        tvp59:"舉例:",
        tvp60:"假設項目獲批資助額為40萬元港幣，當中30萬元港幣由政府資助，其餘10萬元港幣則由申請者承擔。申請者需按照3:1分佈撥款比例先存入同一百分比的款項，即自行存入25,000元港幣 (10萬元港幣的25%) ，然後提供存款證明。確認後才獲發75,000元港幣首期撥款 (即 30 萬元的 25%) 。",
        tvp61:"申請時間: 申請最快何時獲批?",
        tvp62:"創新科技署收到申請書後，會評估申請者資格及根據業務的相關性、預算和項目細節的合理性，以及查核技術顧問或服務供應商的過往記錄。實際批核時間仍需視乎當時收到的申請書數量、個別申請的複雜程度及所提交的資料是否完整和清楚等；評估期間當局或會要求申請者作出澄清或提交補充資料，申請者必須於收到相關要求後一個月內提交文件。",
        tvp63:"終期撥款發放方面，申請者必須於項目完結後兩個月內透過科技券計劃管理系統向創新科技署提交下列文件:",
        tvp64:"點此前往科技券計劃管理系統：",
        tvp65:"1. 項目最終報告",
        tvp66:"2. 項目成果證明 (例如顧問報告、交付收據、 軟件系統的電腦截圖 、 設備硬件的/照片 、舉辦培訓的紀錄，如照片及培訓教材等)",
        tvp67:"3. 每個開支項目的付款發票及相應收據正本或副本，所顯示的付款人名稱必須與申請者名稱相同",
        tvp68:"如果獲批資金超過5萬元港幣，申請者須向創新科技署提交由合資格的獨立審計師審核的開支報表；至於低於5萬元港幣，申請者只須提交最後開支報表並確認無誤。",
        tvp69:"TVP科技券常見問題",
        tvp70:"我是否必須在資助審核成功後才可開展項目?",
        tvp71:"不是，你最早可於遞交申請後翌日開始項目。",
        tvp72:"已開展的項目可以申請科技券資助嗎?",
        tvp73:"可以，但申請者必須在不遲於實際項目開展日期後五個工作天向創新科技署提交填妥的「 聲明及承諾書 」。你可前往",
        tvp74:"下載表格。",
        tvp75:"假如我在香港沒有實際業務或業務大部分在內地/海外進行，我可以申請科技券資助嗎?",
        tvp76:"不可以，兩者皆不符合申請資格。",
        tvp77:" 我名下有兩間公司，其中一間已成功申請科技券資助，我可以再為另一間公司申請同一資助嗎?",
        tvp78:"可以，每間公司最多可獲批6個項目。",
        tvp79:"假如我已為一個科技項目成功申請了其他政府資助，可再申請科技券資助嗎?",
        tvp80:"不可以，同一項目只可接受一個本地政府資助。",
        tvp81:"假如我在項目開展期間需要增加項目總成本，可以申請加大已獲批項目的資助額嗎?",
        tvp82:"不可以，任何修改項目範疇 (即獲批准的科技方案)、或增加核准項目總成本、或增加項目的創新及科技基金資助總額的要求，將不獲受理。",
        tvp83:"聘請服務供應商有什麼要求?",
        tvp84:"你需要尋求外部的科技顧問/服務供應商，就項目提供技術服務或方案以滿足資助要求，包括採購與項目有關的設備/硬件/軟件服務。",
        tvp85:"Q: 申請科技券需要提供多少供應商報價?",
        tvp86:"A: 可以，每間公司最多可獲批6個項目。",
        tvp87:"申請科技券需要提供多少供應商報價?",
        tvp88:"項目價值",
        tvp89:"供應商報價數量",
        tvp90:"不超過50,000元港幣",
        tvp91:"最少2間",
        tvp92:"超過50,000元港幣但不多於 30 萬元港幣",
        tvp93:"最少3間",
        tvp94:"超過 30 萬元港幣但不多於 140 萬元港幣",
        tvp95:"最少5間",
        tvp96:"我可以使用現有公司戶口申請科技券首期撥款嗎?",
        tvp97:"不可以，你須開立一個全新公司戶口用作處理與項目相關的收入及開支。",
        tvp98:"一般辦公室設備、專業服務費或行政費用會否劃入資助內?",
        tvp99:"不會，以下為不劃入資助的例子",
        tvp100:"樓宇租金",
        tvp101:"員工薪金及其他相關開支，包括但不限於強制性公積金供款、約滿 酬金、周年薪酬調整、一般附帶福利(例如醫療)，以及津貼(例如房 屋、交通、逾時工作津貼)、一般培訓及發展",
        tvp102:"日常營運所需的一般辦公室設備／硬件、軟件及科技服務或方案",
        tvp103:"現有及新購置設備的維修保養、保用及保險",
        tvp104:"非與科技相關的專業服務費",
        tvp105:"市場及品牌推廣費用",
        tvp106:"交通和住宿",
        tvp107:"融資支出(例如支付貸款利息)",
        tvp108:"我是否可用現金支付項目費用?",
        tvp109:"可以，但每筆交易限於5,000元港幣或以下。其他非現金支付方式，包括信用卡、支票及銀行轉帳等則無特定金額限制。",
        tvp110:"立即咨詢",
        tvp111:"問：",
        tvp112:"答：",
        url:require('@/assets/img/tvp-2.png'),
    },
    edit:{
        edit01:"私人定制開發",
        edit02:"將客戶每一個需求完美呈現",
        edit03:"範本千篇一律，但每一個企業都有自己的獨門絕技。",
        edit04:"定制開發，深入挖掘客戶的商業特色和真實需求。技術+匠心，精緻打磨，讓您的生意在互聯網脫穎而出。",
        edit05:"20年經驗",
        edit06:"成熟的網站規劃策略和開發技術",
        edit07:"1對1服務",
        edit08:"中港精英工程師團隊全程對接，急速回應",
        edit09:"服務全球客戶",
        edit10:"貼合海外、香港及中國內地的推廣規則",
        edit11:"一站式服務",
        edit12:"解決開發瑣碎流程，服務一步到位",
        edit13:"量身定制式開發",
        edit14:"獨家設計，極大程度提升品牌形象",
        edit15:"系統開發",
        edit16:"會員/項目/商城/OA/ERP/CRM/物流等系統",
        edit17:"各種軟件開發，根據客戶需求定制",
        edit18:"網站開發",
        edit19:"企業門戶網站、外貿建站、商城網站、各行業建站",
        edit20:"自適應網站，多端適配；多語言，全球部署",
        edit21:"APP開發",
        edit22:"Android/iOS原生/混合/跨平臺APP開發",
        edit23:"滿足教育、電商、醫療、汽車等全行業APP開發的業務需求",
        edit24:"微信開發",
        edit25:"H5頁面/微信公眾號/服務/小程式等多種類型微信端應用定制開發",
        edit26:"結合用戶社交、閱讀和生活場景，坐享微信10億流量，輕鬆叩響機遇大門",
        edit27:"中港精英工程師團隊 · 1對1貼心服務",
        edit28:"中天科技20餘年的海內外建站經歷，在中港兩地建立了一支經驗豐富的精英工程師團隊",
        edit29:"全程1對1服務，專人對接，保障溝通及時到位，技術過硬，高效解決需求",
        edit30:"100% 貼合客戶需求",
        edit31:"網站定制開發",
        edit32:"自適應、多語言、全球部署，根據客戶特點及需求，量身定制、優化",
        edit33:"我們的網站定制服務",
        edit34:"根據企業的特點和需求，量身定制",
        edit35:"定制開發一個功能、內容、設計更貼合企業的形象和業務需求的網站",
        edit36:"可以更好地展示企業的產品和服務，優化使用者體驗，凸顯品牌形象，提升企業競爭力",
        edit37:"開發過程和成本靈活可控",
        edit38:"1v1全程對接，開發過程根據企業需求靈活調整，實現成本與時間的最優解",
        edit39:"性能和使用者體驗最大化",
        edit40:"量身定制，對網站安全性和性能進行更加精細的優化",
        edit41:"網站運行速度更快、穩定性更高、安全性更好",
        edit42:"根據網站目標使用者的使用習慣進行設計",
        edit43:"操作更便捷，界面更友好，與使用者快速建立信任感",
        edit44:"客制化的UI/UX設計",
        edit45:"使用者體驗（UX）和使用者界面（UI）設計，能將網站的創意、功能和想法盡數展現。用心設計的UI和UX，能展現出企業優勢，為用戶留下更好的第一印象，留住潛在客戶，創造更多可能性。",
        edit46:"多端適配的自適應網站",
        edit47:"自適應網站採用了彈性佈局，能夠根據訪問設備的螢幕尺寸和解析度自動調整網站的外觀和佈局。多端適配，靈活顯示，能為使用者提供良好的跨設備使用體驗，提高網站的可訪問性，增加用戶參與度和互動性，也更有利於搜尋引擎優化。",
        edit48:"多語言全球化部署",
        edit49:"讓網站訪問者能不受語言和地理位置現實，享受同樣流暢、直觀的用戶體驗，為您量身定制的多語言網站，打造一個專屬於您的全球門戶，能助您的企業在全球同行中脫穎而出，獲得全球用戶的青睞。",
        edit50:"開發工具",
        edit51:"專為你定制設計的網站",
        edit52:"我們量身設計每一個細節，致力於為企業設計最貼合自身特點的網站。客制化的UI/UX設計，多端適配的自適應網站，多語言全球化部署，助力您的企業在數字化時代獲得競爭優勢及效益提升。\n",
        edit53:"一站式量身定制方案",
        edit54:"APP開發",
        edit55:"以手機為載體，拓展市場，創造新的行銷機會，獲取龐大商機",
        edit56:"定制開發APP的優勢",
        edit57:"手機已經成為人們生活中不可或缺的一部分，移動互聯網時代，人們的消費習慣也逐漸從線下轉移到線上。定制一個專屬的APP，方便挖掘移動市場的巨大潛力，讓企業與品牌形象更加深入人心",
        edit58:"教育、電商、醫療、汽車，各行各業的手機APP，都能幫企業節省人力開支，增加收入來源，加強與顧客之間的聯繫。",
        edit59:"選擇更適合你的APP類型",
        edit60:"中天科技20年來服務過數百家企業，為不同行業的企業定制了專屬的APP。",
        edit61:"我們提供商城APP、倉存APP、MRP APP、CRM APP、OA APP，點餐APP，預約APP，以及其他的定制化APP服務，根據你的企業和品牌特色，量身打造。",
        edit62:"開發流程",
        edit63:"一站式服務，全程1對1專人對接，根據實際需求隨時調整開發計畫和流程，實現成本可控，過程透明，省時省心。",
        edit64:"需求溝通",
        edit65:"報價",
        edit66:"界面設計",
        edit67:"技術開發",
        edit68:"測試",
        edit69:"上線",
        edit70:"後期維護",
        edit71:"微信端應用定制開發",
        edit72:"微信小程式、H5頁面、公眾號、服務，多種微信端應用定制開發，10億+微信日活躍用戶，3億+小程式日活躍使用者，",
        edit73:"搭乘頂流社交媒體順風車，輕鬆叩響中國內地市場大門",
        edit74:"無需下載，不占內存，降低使用門檻",
        edit75:"依託微信生態，社交裂變效應，輕鬆觸及目標使用者",
        edit76:"掃碼即用，轉化率高，降低企業獲客成本",
        edit77:"借力公眾號、視頻號，打造內容生態，提升企業形象",
        edit78:"小程式定制開發",
        edit79:"微官網、微商城、O2O平臺、微外賣、微房產、微直播",
        edit80:"一站式服務，精英工程師全程1對1 對接，根據企業需求，量身定制專屬小程式",
        edit81:"中天科技20年技術沉澱，提供個性化高品質定制服務，安全持續保障",
        edit82:"微信公眾號代運營",
        edit83:"優質內容行銷，全行業資源覆蓋，快速傳播品牌形象，提升影響力",
        edit84:"裂變式引流獲客，全管道廣告推廣，搭建私域矩陣",
        edit85:"微商城+客服系統搭建，精准行銷，業績持續增長",
        edit86:"社群搭建，建立清晰用戶畫像，促拓客導流",
        edit87:"小程式的行業機會"
    },
    about:{
        about01:"中天科技",
        about02:"網站開發/APP開發/小程式開發/系統開發/企業IT運維",
        about03:"優質創新的技術和服務",
        about04:"助力中港企業數字化、全球化",
        about05:"核心價值",
        about06:"技術",
        about07:"我們的本錢",
        about08:"用最先進的技術，保證高效性和可靠性",
        about09:"品質",
        about10:"我們的任務",
        about11:"嚴格控制開發過程，確保符合最高的開發標準",
        about13:"服務",
        about14:"我們的宗旨",
        about15:"全面的客戶服務，快速的回應，專業的技術支援和售後服務",
        about16:"創新",
        about17:"我們的目標",
        about18:"緊隨科技前沿，不斷自我突破，開發新產品和解決方案",
        about19:"我們的服務",
        about20:"IT支援服務",
        about21:"網絡與系統維護，降低企業成本，提高企業效率",
        about22:"定制開發",
        about23:"100%還原客戶需求，1對1專人全程對接",
        about24:"網上商城",
        about25:"獲得銷售機會，增加銷售管道，觸達潛在客戶",
        about26:"系統開發",
        about27:"根據企業特點量身定制，契合企業需求，精准提升效益",
        about28:"網站開發",
        about29:"展示企業形象，傳播品牌文化，提高品牌知名度",
        about30:"APP開發",
        about31:"挖掘移動市場巨大潛力，讓品牌形象深入人心",
        about32:"微信開發",
        about33:"微信端應用開發+內容運營，借力社交媒體，讓業務裂變式增長",
        about34:"觀看視頻",
        about35:"https://www.youtube.com/embed/OTlLzEL87yA"
    },
    dtspp:{
        dtspp1:'什麼是數碼轉型支援先導計劃 (DTSPP)？',
        dtspp2: '數碼轉型支援先導計劃（下稱「 本計劃」），會以一對一（1:1）配對方式為本地小型及中型企業（下稱「中小企」）提供資助，以協助中小企應用即用型基本數碼科技方案。香港特別行政區政府（下稱「政府」）已預留港幣5億元用於配對用途。' ,
        dtspp3: '申請本計劃的撥款資助的中小企必須屬於餐飲業或零售業（不包括餐飲業），並必須根據《商業登記條例》（第310章）在香港註冊，並在香港有實質業務經營。它們不得是上市公司、法定機構或接受公帑資助的非政府機構。',
        dtspp4: '香港數碼港管理有限公司（下稱「HKCMCL」）是本計劃的管理機構。如對本指引或計劃有任何疑問，請透過',
        dtspp5: '中列出的途徑聯絡HKCMCL。',
        dtspp6: '目標',
        dtspp7: '本計劃旨在透過為中小企提供一對一配對的撥款資助，支援其在目標方案類別中採用預先評估的現成、可用的數碼科技方案，以實現加快中小企數碼轉型步伐的目標。',
        dtspp8: '該計劃目標的方案類別如下：',
        dtspp9: '電子支付系統及店面銷售',
        dtspp10: '協助企業自動化收款和計算的工作流程，並支援電子支付以開拓新收款渠道。該類型的系統亦可包括自助式店面銷售方案，如自助落單系統和自助售賣機等。系統可透過營銷數據自動生成報表，讓企業分析其業務狀況，為業務決策提供依據。',
        dtspp11: '線上推廣',
        dtspp12: '為企業建設公司網站、社交媒體專頁，或透過線上搜索引擎和社交媒體幫助他 們推廣業務。',
        dtspp13: '客戶管理及優惠系統',
        dtspp14: '該類型的系統可支援銷售推廣活動如電子會員計劃和電子優惠券等。系統亦可提供客戶支援、個案管理及知識庫等功能，並可提供報表讓企業有效地檢視、分析及管理銷售活動、目標、所發掘的潛在客戶及相關跟進工作，讓企業能與現有及潛在客戶增加互動，並集中儲存客戶資料和聯繫記錄。',
        dtspp15: '資助範圍',
        dtspp16: '如果設備、硬件、軟件和任何其他資產構成方案組合的重要組成部分，則允許運用資助以作購買、租賃或訂閱，但須遵守以下規則：',
        dtspp17: '以本計劃的資金助所購買的新設備、硬件、軟件和任何其他資產的所有權和權益應歸申請人所有；',
        dtspp18: '以本計劃的資金助所購買、採購或租賃的所有物品應為持牌產品，且不得侵犯任何第三方的知識產權；和',
        dtspp19: '使用該設備、硬件、軟件和任何其他資產的風險，將由申請人承擔並由申請人保留。',
        dtspp20: '獲批准的申請的方案應在申請獲得批准後 9 個月內落實執行並準備好供申請人使用。對於訂閱形式的方案而言，允許在訂閱期內提供最多兩年的撥款資助。',
        dtspp21: '資助金額',
        dtspp22: '每位合資格申請人將按照方案清單所列的方案的費用，以一對一配對的方式，提供高達港幣50,000元的資助。申請人必須分擔所採用的方案的總成本的至少一半。',
        dtspp23: '申請人的實物捐贈或任何非金錢代價將不被接受。',
        dtspp24: '獲批准的申請項目（包括但不限於申請人或本計劃資助的項目）不得接受其他本地公共資金來源的資助',
        dtspp25: '申請程序',
        dtspp26: '本計劃將分批向申請人開放申請，每個申請批次將關於一個指定行業。在後續的批次申請中，將於方案清單上陸續增加新的數碼科技方案和方案類別，讓申請者有更豐富的選擇。',
        dtspp27: '每個申請批次的時間表和可供選擇的數碼科技方案類別，請見',
        dtspp28: '已提交申請且其申請仍有待批准的申請人，在其原先提交的申請被拒絕或撤回之前，不得再次提交申請。',
        dtspp29: '其申請已獲批准的申請人，不得再次提出申請。',
        dtspp30: '在提交申請之前，申請人須先在本計劃的網站登記一個申請人帳戶。申請人可以在方案清單中確定其想採用的方案，並直接與方案供應商聯繫，以了解該方案的功能並確定該方案是否滿足其要求。申請人須在相關的方案頁面點擊「查詢」按鈕使用本計劃的網站，以向方案供應商確認是否可向申請人提供該方案組合。在收到方案供應商確認後，申請人即可繼續完成網上申請表。',
        dtspp31: '在此建議申請人，在選擇他們想採用的方案之前，應了解和比較不同的方案和方案供應商。每份申請只能選擇一個方案組合。',
        dtspp32: '請注意，獲列入方案清單，並不表示任何方案或方案供應商獲得政府或HKCMCL的認可。',
        dtspp33: '申請人須透過本計劃的網站提交其申請。透過其他方式提交的申請（包括但不限於電子郵件或郵寄）均不獲接納。',
        dtspp34: '申請資格',
        dtspp35: '已根據《商業登記條例》（第310章）在香港註冊；',
        dtspp36: '並非香港上市公司、法定機構或接受公帑資助的非政府機構；',
        dtspp37: '申請時在香港以下行業（「指定行業」）擁有實質業務運作：',
        dtspp38: '餐飲業',
        dtspp39: '零售業（不包括餐飲業）；',
        dtspp40: '全職員工少於50名',
        dtspp41: '不包括兼職員工',
        dtspp42: '對於以不同公司註冊但屬同一母公司下持有的公司，公司集團內的所有全職員工將被計算在內，且應少於50人，申請人才合資格參加此計劃',
        dtspp43: '已批准申請或待批准申請的申請人不得再提交申請',
        dtspp44: '提交期間所需的資訊',
        dtspp45: '公司基本資料：',
        dtspp46: '公司名',
        dtspp47: '公司地址',
        dtspp48: '商業登記證號碼',
        dtspp49: '行業類別',
        dtspp50: '主要聯絡人資訊（姓名、手機號碼、電子郵件地址）',
        dtspp51: '選定方案',
        dtspp52: '證明文件（商業證明文件）',
        dtspp53: '提交文件',
        dtspp54: '商業證明',
        dtspp55: '申請時間表－資助申請人(SME)',
        dtspp56: '方案類別',
        dtspp57: '接受申請日期',
        dtspp58: '截止日期',
        dtspp59: '批次 #1(餐飲業方案申請)',
        dtspp60: '資助申請人可選方案類別:',
        dtspp61: '基礎:',
        dtspp62: '電子支付系統及店面銷售',
        dtspp63: '(可選)',
        dtspp64: '線上推廣',
        dtspp65: '客戶管理及優惠系統',
        dtspp66: '即將推出',
        dtspp67: '批次 #2(零售業方案申請)',
        dtspp68: '備註:',
        dtspp69: '逾期提交的申請將不予受理。',
        dtspp70: '申請開始時間為每批次接受申請日期的香港時間中午12時。',
        dtspp71: '截止申請時間為每批次截止申請日期的香港時間下午11時59分。',
        dtspp72: '申請程序',
        dtspp73: '步驟',
        dtspp74: '資助撥款給方案供應商',
        dtspp75: '金額 (佔批准金額的百分比)',
        dtspp76: '條件',
        dtspp77: '階段',
        dtspp78: '在申請人確認方案已實施並提供令人滿意的證據（例如方案供應商的收據）表明其已就本計劃的網站上所採用的方案支付相應份額的費用後，將向方案供應商支付獲批准撥款資助的50%的首期付款。',
        dtspp79: '申請獲批准12個月後，資助申請人須遞交總結報告及提交相關證明文件並獲HKCMCL妥為接納後才會發放剩餘款項予方案供應商。',
        dtspp80: '商業登記證',
        dtspp81: '商業登記證有效商業登記證（註：商業登記證上的公司名稱必須與報名提交資料上指定的公司名稱相符）',
        dtspp82: '公司註冊證書',
        dtspp83: '公司註冊證書（註：公司註冊證書上的公司名稱必須與申請中列明的機構名稱相符）',
        dtspp84: '聲明',
        dtspp85: '由公司持有人/董事簽署並加蓋公司印章的申請人聲明',
        dtspp86: '店面照片',
        dtspp87: '顯示指定地址有大量且實質性業務的店面照片',
        dtspp88: '地址證明',
        dtspp89: '<p>以下文件之一，證明申請人在申請中指定的地址開展實質業務：</p>'
            +'<ul><li>過去 3 個月內的電費帳單； 或</li><li>過去 2 個季度的水費單； 或<li>租賃協議副本（租戶姓名必須與申請中的公司名稱一致）</li></ul>',
        dtspp90: '合資格食環署牌照',
        dtspp91: '<p>*只限餐飲業</p>提供其中一份合資格食物環境衛生署牌照副本',
        dtspp92: '登記一個資助申請人帳戶',
        dtspp93: '於網站中選擇方案',
        dtspp94: '聯繫方案供應商並選擇方案',
        dtspp95: '方案供應商在入口網站中表明“提供意向”',
        dtspp96: '在線提交申請',
        dtspp97: '檢查資格和完整性',
        dtspp98: '提交評審委員會審批',
        dtspp99: '批准申請人向供應商下訂單',
        dtspp100: '批准申請人向方案供應商下訂單',
        dtspp101: '中小企業在門戶網站中確認方案交付',
        dtspp102: '證明文件審批',
        dtspp103: '發放第一筆資助款項 (50%)',
        dtspp104: '中小企在門戶網站提交總結報告',
        dtspp105: '檢查報告的完整性和證明文件',
        dtspp106: '發放第二筆資助款項'
    },
    cloud:{
        cloud01:'Device Protect365 -',
        cloud0101:'雲端備份',
        cloud02:'“這將顛覆雲端產業——這是同類產品中的第一個，具有無限軟件授權，可備份設備和 SaaS 服務，包括',
        cloud03:'Servers, Endpoints, Databases, VMware, Hyper-V, Synology, QNAP, Dropbox, Google Drive and Microsoft 365 的一體化解決方案”',
        cloud04:'Saaher Muzafer, Backup Everything的首席執行官.',
        cloud05:'電子郵件報告',
        cloud06:'每日通知',
        cloud07:'支援',
        cloud08:'免費和包含',
        cloud09:'軟件授權',
        cloud10:'無需支付任何費用',
        cloud11:'儲存',
        cloud12:'多個地區',
        cloud13:'介紹 Device Protect365',
        cloud14:'Device Protect365 是一款雲端備份解決方案，透過自動增量備份保護您的數據，讓您放心，如果發生數據問題，您將能夠隨時恢復數據。 好處是我們提供免費許可證，因此您可以備份無限的用戶和計算機，此外我們還讓您可以靈活地選擇要儲存資料的位置。',
        cloud16:'備份實體和虛擬伺服器',
        cloud17:'保護您的 Windows 和 Mac 端點',
        cloud18:'備份資料庫、Synology 和 QNAP',
        cloud19:'備份 Microsoft 365、Google Drive 和 Dropbox',
        cloud20:'來自 AWS、Azure、Google CP 等的備份儲存',
        cloud21:'包含無限軟件授權',
        cloud22:'規劃自動備份和增量備份',
        cloud23:'快速輕鬆的恢復',
        cloud24:'Files & Folders',
        cloud25:'Windows',
        cloud26:'Mac',
        cloud27:'Linux',
        cloud28:'Windows System Backup',
        cloud29:'MS SQL',
        cloud30:'My SQL',
        cloud31:'Synology',
        cloud32:'Hyper-V',
        cloud33:'VMware',
        cloud34:'Oracle DB',
        cloud35:'Maria DB',
        cloud36:'QNAP',
        cloud37:'MS Exchange',
        cloud38:'MS Exchange (Granular)',
        cloud39:'M365 - Exchange',
        cloud40:'M365 - SharePoint',
        cloud41:'M365 - OneDrive',
        cloud42:'M365 - Teams',
        cloud43:'M365 - Groups',
        cloud44:'Wasabi',
        cloud45:'AWS',
        cloud46:'Azure',
        cloud47:'Google Cloud',
        cloud48:'Backblaze',
        cloud49:'Rackspace',
        cloud50:'Google Drive',
        cloud51:'Dropbox',
        cloud52:'FTP',
        cloud53:'SFTP',
        cloud54:'輕鬆資料恢復',
        cloud55:'透過我們友好的用戶介面快速輕鬆地恢復您的數據',
        cloud56:'隨著您的成長而擴展',
        cloud57:'隨著您的成長添加、刪除或擴展您的備份計劃',
        cloud58:'客戶支援',
        cloud59:'我們隨時為您提供協助，讓您的備份永遠不會中斷。',
        cloud60:'自動備份',
        cloud61:'自動定期備份以防止資料遺失',
        cloud62:'多個儲存位置',
        cloud63:'選擇將資料異地儲存在多個區域或本地',
        cloud64:'領先的加密技術',
        cloud65:'256 AES 加密可保護您的飛行和靜態數據',
        cloud66:'您可以將哪些內容備份到我們的雲端和本地端？',
        cloud67:'安全與合規性',
        cloud68:'我們的解決方案基於最新的編碼、安全性和合規性協議而構建,\n' +
            '因此請放心，您的資料將被安全存儲，沒有第三方存取。.',
        cloud69:'常見問題',
        cloud70:'問: Device Protect365 與其他提供者有何不同？',
        cloud71:'簡單來說，這產品沒有許可成本，並且僅以每 TB 為基礎進行儲存。 ',
        cloud72:'問: 使用你們的備份解決方案，我的資料的安全性如何？',
        cloud73:'我們使用業界領先的安全協定來確保您的資料在傳輸過程中和靜態時都是安全的。 我們的資料中心位於具有 24/7 監控的安全設施中，我們使用加密來保護您的資料免受網路威脅。',
        cloud74:'問: 設定和管理所有解決方案是否容易？',
        cloud75:'是的！ 我們的備份解決方案設計容易操作，具有簡單直覺的介面，不需要特殊的技術知識。 此外，我們也提供客戶支持，協助您解決可能遇到的任何疑問或問題。',
        cloud76:'問: 你們的備份解決方案可以隨著我的業務擴展嗎？',
        cloud77:'是的！ 我們的雲端備份解決方案旨在與您的業務一起發展，因此您可以根據需要輕鬆新增或刪除資料。 此外，我們靈活的定價選項可讓您隨著業務的發展輕鬆擴大或縮小規模。',
        cloud78:'問: 你們的解決方案多久備份一次我的資料？',
        cloud79:'您可以自訂備份計劃以滿足您的業務需求。 選擇您希望備份資料的頻率和時間。',
        cloud80:'問: 如果發生資料遺失，我可以多快恢復資料？',
        cloud81:'我們的介面可讓您在資料遺失時輕鬆快速地恢復資料。 對於裝置解決方案，最好建立本機副本，以便您也可以從 LAN 速度復原。',
        cloud82:'問: 你們支援哪些平台和作業系統？',
        cloud83:'我們可以備份各種平台和作業系統，包括 Windows、macOS 和 Linux。 另外還有 MS SQL、MySQL、VMware、Hyper-V、Synology 和 QNAP。',
    }
}
